import React, {useContext, useState} from 'react';
import AccountContext from "../../contexts/AuthenticationContext";
import Select from "react-select";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import isEmail from "validator/es/lib/isEmail";
import * as api from "../../services/AuthenticationService";
import {GENDERS, LEVEL_OPTIONS, T_SHIRT_OPTIONS} from "../../utils/constants";
import isEmpty from "validator/es/lib/isEmpty";
import {useNavigate} from "react-router-dom";
import {Container} from "@mui/material";
import Form from "react-bootstrap/Form";
import InfoIconWithTooltipRight from "../../components/InfoIconWithTooltip/InfoIconWithTooltipRight";
import {calculateAge} from "../../utils/toolbox";


const Register = () => {
    const navigate = useNavigate();

    const {authenticate} = useContext(AccountContext);
    const [globalError, setGlobalError] = useState();
    const [emailError, setEmailError] = useState();
    const [passwordError, setPasswordError] = useState();
    const [verifyPasswordError, setVerifyPasswordError] = useState();
    const [addressError, setAddressError] = useState();
    const [localityError, setLocalityError] = useState();
    const [birthDateError, setBirthDateError] = useState();
    const [postalCodeError, setPostalCodeError] = useState();
    const [phoneError, setPhoneError] = useState();
    const [lastNameError, setLastNameError] = useState();
    const [firstNameError, setFirstNameError] = useState();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [verifyPassword, setVerifyPassword] = useState();
    const [gender, setGender] = useState();
    const [street, setStreet] = useState();
    const [houseNumber, setHouseNumber] = useState();
    const [box, setBox] = useState();
    const [birthDate, setBirthdate] = useState();
    const [locality, setLocality] = useState();
    const [postalCode, setPostalCode] = useState();
    const [phone, setPhone] = useState();
    const [lastName, setLastName] = useState();
    const [firstName, setFirstName] = useState();
    const [level, setLevel] = useState();
    const [tShirt, setTShirt] = useState();
    const [dataAuthorization, setDataAuthorization] = useState(false);
    const [imageAuthorization, setImageAuthorization] = useState(false);

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            if (emailError || passwordError || verifyPasswordError || addressError || localityError || birthDateError || postalCodeError || phoneError || lastNameError || firstNameError) {
                return setGlobalError('un element du formulaire n\'est pas valide');
            }
            if (dataAuthorization === false) {
                setGlobalError('vous n\'avez pas accepter l\'utilisations des des données')
            } else {
                await api.register(lastName, firstName, gender, birthDate, street, houseNumber, box, postalCode, locality, phone, level, tShirt, email, password, imageAuthorization);
                navigate("/login");
            }
        } catch (e) {
            if (e.response.data.details === undefined) {
                setGlobalError(e.response.data);
            } else if (e.response.data.details.body[0]) {
                setGlobalError(e.response.data.details.body[0].message);
            }

        }
    }

    const handleEmailChange = email => {
        if (!isEmail(email)) {
            setEmailError("L'email est invalide.");
            return;
        }

        setEmail(email);
        setEmailError(null);
    }
    const handleBack = () => {
        navigate("/login");
    }

    const handlePasswordChange = password => {
        if (password.length < 8) {
            setPasswordError("Le mot de passe doit contenir au minimum 8 caractères.");
            return;
        }

        setPassword(password);
        setPasswordError(null);
    }

    const handleVerifyPasswordChange = verifyPassword => {
        if (password !== verifyPassword) {
            setVerifyPasswordError("Les mots de passes ne correspondent pas.");
            return;
        }

        setVerifyPassword(verifyPassword);
        setVerifyPasswordError(null);
    }

    const handleStreetChange = address => {
        if (isEmpty(address)) {
            setAddressError("La rue est invalide.");
            return;
        }

        setStreet(address);
        setAddressError(null);
    }
    const handleHouseNumber = address => {
        if (isEmpty(address)) {
            setAddressError("Le numéros de rue est invalide.");
            return;
        }

        setHouseNumber(address);
        setAddressError(null);
    }
    const handleBoxChange = address => {
        if (isEmpty(address)) {
            setAddressError("La boite est invalide.");
            return;
        }

        setBox(address);
        setAddressError(null);
    }

    const handleBirthDateChange = birthDate => {
        if (calculateAge(birthDate) < 18) {
            setBirthDateError("Vous devez avoir plus de 18 ans pour vous inscrire.");
            return;
        }
        setBirthdate(birthDate);
        setBirthDateError(null)
    }

    const handleLocalityChange = locality => {
        if (!locality) {
            setLocalityError("La région est invalide.");
            return;
        }

        setLocality(locality);
        setLocalityError(null);
    }

    const handlePostalCodeChange = postalCode => {
        if (!postalCode) {
            setPostalCodeError("Le code postal est invalide.");
            return;
        }

        setPostalCode(postalCode);
        setPostalCodeError(null);
    }

    const handlePhoneChange = phone => {
        if (!isMobilePhone(phone)) {
            setPhoneError("Le numéro est incorrect.");
            return;
        }

        setPhone(phone);
        setPhoneError(null);
    }

    const handleLastNameChange = lastName => {
        if (isEmpty(lastName)) {
            setLastNameError("Veuillez entrer le nom.");
            return;
        }

        setLastName(lastName);
        setLastNameError(null);
    }

    const handleFirstNameChange = firstName => {
        if (isEmpty(firstName)) {
            setFirstNameError("Veuillez entrer le prénom.");
            return;
        }

        setFirstName(firstName);
        setFirstNameError(null);
    }

    const handleLevelChange = level => {
        setLevel(level.value);
    }

    const handleTShirtChange = tShirt => {
        setTShirt(tShirt.value);
    }

    return (
        <div className="flex bg-backgroundEV   m-auto min-h-screen  text-white overflow-auto ">
            <Container className="mx-auto ">
                <div className=" text-center desktop:m-auto tablet:m-5 bg-gray-700 rounded-lg p-4">
                    <div className=" border-2 rounded-sm  border-red-500 mb-2 ">
                        <p className="text-center text-xl m-3">Merci de vous inscrire en votre propre nom, il sera
                            possible d'ajouter votre enfant sur votre profil et de l'inscrire aux différents
                            cours/activités par la suite.</p>
                    </div>
                    {/* lastName */}
                    <label className="mb-2  justify-center">
                        <p className="mr-2">Nom:</p>
                        <input type="text" onChange={e => handleLastNameChange(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                        />
                    </label>

                    {
                        lastNameError &&
                        <p className="text-red-700">{lastNameError}</p>
                    }

                    {/* firstName */}
                    <label className="mb-2  justify-center">
                        <p className="mr-2">Prénom:</p>
                        <input type="text" onChange={e => handleFirstNameChange(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                        />
                    </label>

                    {
                        firstNameError &&
                        <p className="text-red-700">{firstNameError}</p>
                    }

                    {/* email */}
                    <label className="mb-2  justify-center">
                        <p className="mr-2">Email:</p>
                        <input type="email" onChange={e => handleEmailChange(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                               placeholder="you@example.com"/>
                    </label>

                    {
                        emailError &&
                        <p className="text-red-700">{emailError}</p>
                    }

                    {/* password */}
                    <label className="mb-2 flex justify-center">
                        <div className="mr-2">
                            <p className="mr-2">Mot de passe:</p>
                            <input type="password" onChange={e => handlePasswordChange(e.target.value)}
                                   autoComplete="new-password"
                                   className="shadow bg-transparent border  rounded w-full py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                            />
                        </div>

                        {
                            passwordError &&
                            <p className="text-red-700">{passwordError}</p>
                        }

                        <div>
                            <p className="mr-2">Confirmez:</p>
                            <input type="password" onChange={e => handleVerifyPasswordChange(e.target.value)}
                                   autoComplete="new-password"
                                   className="shadow bg-transparent border  rounded w-full py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                            />
                        </div>

                        {
                            verifyPasswordError &&
                            <p className="text-red-700">{verifyPasswordError}</p>
                        }
                    </label>

                    {/* gender */}
                    <div className="flex justify-center">
                        <p className="mr-2">Sexe:</p>
                        {GENDERS.map((gender, i) => (
                            <div key={i} className="form-check form-check-inline">
                                <Form.Check
                                    inline
                                    label={gender}
                                    name="inlineRadioOptions"
                                    type="radio"
                                    value={gender}
                                    id="inlineRadio2"
                                    onChange={e => setGender(e.target.value)}/>
                            </div>
                        ))}
                    </div>

                    {/* address */}
                    {
                        addressError &&
                        <p className="text-red-700">{addressError}</p>
                    }
                    <label className="mt-5  justify-center ">
                        <p className="mr-2">rue:</p>
                        <input type="text" onChange={e => handleStreetChange(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                        />
                    </label>
                    <label className="mt-5  justify-center ">
                        <p className="mr-2">numero:</p>
                        <input type="text" onChange={e => handleHouseNumber(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                        />
                    </label>
                    <label className="mt-5  justify-center ">
                        <p className="mr-2">boite:</p>
                        <input type="text" onChange={e => handleBoxChange(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                        />
                    </label>


                    {/* postalCode */}
                    {
                        postalCodeError &&
                        <p className="text-red-700">{postalCodeError}</p>
                    }
                    <label className="mt-5  justify-center">
                        <p className="mr-2">Code postal:</p>
                        <input type="number" onChange={e => handlePostalCodeChange(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                        />
                    </label>


                    {/* locality */}
                    {
                        localityError &&
                        <p className="text-red-700">{localityError}</p>
                    }
                    <label className="mt-5  justify-center">
                        <p className="mr-2">Localité:</p>
                        <input type="text" onChange={e => handleLocalityChange(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                        />
                    </label>


                    {/* phone */}
                    {
                        phoneError &&
                        <p className="text-red-700">{phoneError}</p>
                    }
                    <label className="mt-5  justify-center">
                        <p className="mr-2">Téléphone:</p>
                        <input type="tel" onChange={e => handlePhoneChange(e.target.value)}
                               className="shadow bg-transparent border  rounded w-auto py-2 px-3  mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                        />
                    </label>


                    {/* birthdate */}
                    {
                        birthDateError &&
                        <p className="text-red-700">{birthDateError}</p>
                    }
                    <label className=" justify-center">
                        <p>Date de naissance:</p>
                        <input type="date" required pattern="\d{4}-\d{2}-\d{2}"
                               onChange={e => handleBirthDateChange(e.target.value)}
                               className="mt-1 mb-4  w-60 bg-transparent border rounded px-2 py-2"/>
                    </label>

                    {/* level */}
                    <div className="flex justify-center">
                            <span>Niveau d'escalade:
                                <Select className="text-black ml-5 " defaultValue={level} options={LEVEL_OPTIONS}
                                        onChange={handleLevelChange}/>
                            </span>
                    </div>

                    {/* tShirt */}
                    <div className="flex mt-3 justify-center">
                            <span>Taille de t-shirt:
                                <Select className="text-black ml-5 " defaultValue={tShirt} options={T_SHIRT_OPTIONS}
                                        onChange={handleTShirtChange}/>
                            </span>
                    </div>
                    <div className=' justify-center'>
                        {
                            // add mandatory checkbox about data privacy
                        }
                        <div className="flex mt-3 justify-center ">
                            <input type="checkbox" className="mt-1 mr-2"
                                   onChange={e => {
                                       setDataAuthorization(e.target.checked)
                                       setGlobalError(null)
                                   }}/>
                            <p className="text-red-700 mr-1">*</p>
                            <p>j'accepte que les données soient utilisées dans le cadre de
                                l'association </p>
                            <InfoIconWithTooltipRight
                                info={'les informations stockées sont uniquement celles nécessaires pour la gestion de l\'association, et le suivi des cours.  Nous nous engageons à ne jamais utiliser ces données à des fins commerciales.  Toute demande de suppression des données doit être faite par mail à secretariat@evolutionverticale.be'}/>

                        </div>
                        {
                            // add mandatory checkbox about pictures of the child
                        }
                        <div className="flex mt-1 justify-center ">
                            <input type="checkbox" className="mt-1 mr-2"
                                   onChange={e => {
                                       setImageAuthorization(e.target.checked)
                                   }}/>
                            <p className="text-red-700 mr-1"></p>
                            <p> J'accepte que les photos soient utilisées dans le cadre de
                                l'association</p>
                            <InfoIconWithTooltipRight
                                info={'J\'accepte que les photos prises durant les activités du club peuvent servir à des fins promotionelles sur le site internet et réseaux sociaux du club.'}/>
                        </div>
                    </div>
                    {globalError &&
                        <p className="text-red-700 mt-3 -mb-3 font-bold">{globalError}</p>
                    }

                    <label className="flex flex-row justify-center">
                        <div className="basis-1/5">
                            <button
                                onClick={handleSubmit}
                                className="  rounded font-bold  bg-pinkEv  border-gray-500 border-2  p-3 focus:border-transparent hover:border-pinkEv m-3">
                                S'inscrire
                            </button>
                        </div>
                        <div className="basis-1/5">
                            <button
                                className="  rounded font-bold  bg-pinkEv  border-gray-500 border-2  p-3 focus:border-transparent hover:border-pinkEv m-3"
                                onClick={handleBack}>
                                retour
                            </button>
                        </div>
                    </label>

                </div>
            </Container>
        </div>)

}

export default Register
