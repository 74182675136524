import React, {useContext, useEffect, useState} from 'react';
import AddChildModal from "../../components/Modals/AddChildModal";
import * as api from "../../services/AccountService";
import {
    getAccount,
    getMutualDocument,
    getOrdersHistory,
    getOwnedBrevets
} from "../../services/AccountService";
import Child from "../../components/Child/Child";
import CartContext from "../../contexts/CartContext";

import ListInscriptions from "../../components/Home/ListInscriptions";
import EditInfoModal from "../../components/Modals/EditInfoModal";
import {ifExpiredDisconnect} from "../../utils/toolbox";
import ListBrevets from "../../components/Home/ListBrevets";
// <ListBrevets OwnedBrevetsList={brevetsList}/>

const HomePage = () => {
    const {updateCart} = useContext(CartContext);
    const [addChildModal, setAddChildModal] = useState(false);
    const [childList, setChildList] = useState([]);
    const [updateChild, setUpdateChild] = useState(false);
    const [showList, setShowList] = useState(false);
    const [currentUser, setCurrentUser] = useState();
    const [listeInscription, setListInscription] = useState([]);
    const [brevetsList, setBrevetsList] = useState([]);
    const [showBrevets, setShowBrevets] = useState(false);
    const [editInfoModal, setEditInfoModal] = useState(false);
    const [errorDoc, setErrorDoc] = useState()

    useEffect(() => {
        const id = localStorage.getItem('userid')
        ifExpiredDisconnect()
        Promise.all([api.getCurrentChildrens(), getAccount(id), getOrdersHistory(id), getOwnedBrevets(id)])
            .then(values => {
                setChildList(values[0]);
                setCurrentUser(values[1]);
                //format order_date to dd/mm/yyyy
                values[2].map((order) => {
                    order.order_date = new Date(order.order_date).toLocaleDateString('fr-FR')
                    if (order.status === 'STRIPE_CHECKOUT_SESSION_COMPLETED') {
                        order.status = <span
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Payé</span>
                    } else if (order.status === 'PAYMENT_CREATED') {
                        order.status = <span
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">En cours</span>
                    } else if (order.status === 'PAYMENT_FAILED') {
                        order.status = <span
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Echoué</span>
                    } else if (order.status === 'CREATED') {
                        order.status = <span
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">Crée</span>
                    } else if (order.status === 'STRIPE_CHECKOUT_SESSION_EXPIRED') {
                        order.status = <span
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Payment expirer</span>
                    }
                })
                setListInscription(values[2]);
                setBrevetsList(values[3]);
            })
            .catch(err => {
                console.log(err);
            })
        updateCart();



    }, [updateChild])


    const onCancel = () => {
        setAddChildModal(false)
        setEditInfoModal(false)
    }

    const onUpdate = () => {
        setUpdateChild(!updateChild);
    }
    const handleOnClickOwnedBrevets = async () => {
        setShowList(false);
        setShowBrevets(!showBrevets);
    }
    const handleDelete = async (id) => {
        await api.deleteChild(id);
        setUpdateChild(!updateChild);
    }

    const dowload = async () => {
        try {
            await getMutualDocument(localStorage.getItem("userid"));
        }catch (e) {
            setErrorDoc('vous n\'avez pas d\'inscriptions')
        }
    }
    const handleShowList = () => {
        setShowBrevets(false);
        setShowList(!showList)
    }

    const handleShowEdit = () => {
        setEditInfoModal(!editInfoModal)
    }
    return (
        <React.Fragment>
            <h2 className="absolute top-0 left-10 font-sans  text-xl">Home Page</h2>
            <div className="flex flex-wrap md:flex-row h-screen m-1">
                {showList ?
                    <div className="m-auto  content-center ">
                        {errorDoc &&
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                <strong className="font-bold">Erreur</strong>
                                <span className="block sm:inline">{errorDoc}</span>
                                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                                             xmlns="XXXXXXXXXXXXXXXXXXXXXXXXXX" viewBox="0 0 20 20" onClick={()=>{
                                            setErrorDoc(null)
                                        }}>
                                            <title>Close</title>
                                            <path
                                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                                        </svg>
                                    </span>

                            </div>
                        }
                        <button
                            className=" m-2 border rounded p-1 transition duration-500 ease-in-out transform hover:-translate-y-1"
                            onClick={dowload}>Générer mon attestation pour ma mutuelle
                        </button>
                        <button
                            className=" m-2 border rounded p-1 transition duration-500 ease-in-out transform hover:-translate-y-1"
                            onClick={handleShowList}>Masquer mes inscriptions
                        </button>
                        <button
                            className=" m-2 border rounded p-1 transition duration-500 ease-in-out transform hover:-translate-y-1"
                            onClick={handleShowEdit}> éditer mes informations
                        </button>
                        <div>
                            <ListInscriptions ordersList={listeInscription}/>
                        </div>
                    </div>
                    :
                    <div className="m-auto  content-center ">
                        {currentUser ?
                            <h2>Bonjour {currentUser.first_name}</h2>
                            : <h2>Bonjour</h2>
                        }
                        {errorDoc &&
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                <strong className="font-bold">Erreur</strong>
                                <span className="block sm:inline">{errorDoc}</span>
                                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                                             xmlns="XXXXXXXXXXXXXXXXXXXXXXXXXX" viewBox="0 0 20 20" onClick={()=>{
                                                 setErrorDoc(null)
                                        }}>
                                            <title>Close</title>
                                            <path
                                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                                        </svg>
                                    </span>

                            </div>
                        }
                        <button
                            className=" m-2 border rounded p-1 transition duration-500 ease-in-out transform hover:-translate-y-1"
                            onClick={dowload}>Générer mon attestation pour ma mutuelle
                        </button>
                        <button
                            className=" m-2 border rounded p-1 transition duration-500 ease-in-out transform hover:-translate-y-1"
                            onClick={handleShowList}>Afficher mes inscriptions
                        </button>
                        <button
                            className=" m-2 border rounded p-1 transition duration-500 ease-in-out transform hover:-translate-y-1"
                            onClick={handleShowEdit}>Editer mes informations
                        </button>
                        {showBrevets &&
                        <div className='max-w-full overflow-x-auto'>
                            <ListBrevets OwnedBrevetsList={brevetsList}/>
                        </div>
                    }
                    </div>
                }
                <div className=" flex mt-3 mb-auto mr-12">
                    <div className=" inset-y-0 right-1  ">
                        <h4>Liste des membres de la famille:</h4>
                        <table className="min-w-full shadow-lg  border">
                            <thead className="">
                            <tr className=" border ">
                                <th className="p-3 text-left font-bold">Nom</th>
                                <th className="p-3 text-left font-bold">Prénom</th>
                                <th className="p-3 text-left font-bold"/>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-300 ">
                            {currentUser &&
                                <tr>
                                    <td className="p-3">
                                        {currentUser.last_name}
                                    </td>
                                    <td className="p-3">
                                        {currentUser.first_name}
                                    </td>
                                    <td>
                                        <button
                                            className=" border rounded p-1 transition duration-500 ease-in-out  transform hover:-translate-y-1"
                                            onClick={handleOnClickOwnedBrevets}>
                                            brevets
                                        </button>
                                    </td>
                                </tr>
                            }
                            {childList.length === 0 ?
                                <tr>
                                </tr>
                                :
                                childList.map(child =>
                                    <Child key={child.id} child={child} handleDelete={handleDelete}/>
                                )
                            }

                            </tbody>
                        </table>
                        <button onClick={() => {
                            setAddChildModal(true)
                        }}
                                className=" border rounded p-1 border-pinkEv mt-1  bg-pinkEv text-white transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-auto hover:text-pinkEv">
                            Ajouter un membre à ma famille
                        </button>
                    </div>
                </div>
            </div>

            {/* modal add child */}
            {
                addChildModal &&
                <AddChildModal onCancel={onCancel} onupdate={onUpdate} setChildList={setChildList}/>
            }
            {
                editInfoModal &&
                <EditInfoModal onCancel={onCancel}/>
            }
        </React.Fragment>
    )
}

export default HomePage;
