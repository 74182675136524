import axios from "axios";
import download from 'downloadjs'
import {url} from "../utils/constants";


/*
axios.interceptors.request.use((config) => {
    config.headers.Authorization = localStorage.getItem('token');
    return config;
}, (error) => {
    return Promise.reject(error);
})

 */


const client = axios.create({
    baseURL: url + "accounts"
})

const getAllAccounts = async () => {
    try {
        const accounts = await client.get("/", {headers: {'Authorization': localStorage.getItem('token')}});
        return accounts.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const getTeamMembers = async () => {
    try {
        const accounts = await client.get("/team-members", {headers: {'Authorization': localStorage.getItem('token')}});
        return accounts.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const getAllAccountsNames = async () => {
    try {
        const accounts = await client.get("/names", {headers: {'Authorization': localStorage.getItem('token')}});
        return accounts.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const getChildAccount = async (childId) => {
    try {
        try {
            const id = localStorage.getItem('userid');
            const child = await client.get("/" + id + "/children/" + childId, {headers: {'Authorization': localStorage.getItem('token')}});
            return child.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const getAccount = async (childId) => {
    try {
        const child = await client.get("/" + childId, {headers: {'Authorization': localStorage.getItem('token')}});
        return child.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const getCurrentAccount = async () => {
    try {
        const id = localStorage.getItem('userid');
        const account = await client.get("/" + id, {headers: {'Authorization': localStorage.getItem('token')}});
        return account.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const getCurrentChildrens = async () => {
    try {
        const id = localStorage.getItem('userid');
        const children = await client.get("/" + id + "/children", {headers: {'Authorization': localStorage.getItem('token')}});
        return children.data;
    } catch (e) {
        if (e.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('userid');
            localStorage.removeItem('right');
            window.location.href = "/";
        }
        console.error(e.response.data);
        throw e;
    }
}

const getChildrens = async (id) => {
    try {
        const children = await client.get("/" + id + "/children", {headers: {'Authorization': localStorage.getItem('token')}});
        return children.data;
    } catch (e) {
        if (e.response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('userid');
            localStorage.removeItem('right');
            window.location.href = "/";
        }
        console.error(e.response.data);
        throw e;
    }
}


const addChild = async (lastName, firstName, birthDate, gender, level, tShirt,allowGoingHomeAlone,responsibleToRetrive,language,imageRight) => {
    try {
        let lastNameMaj=lastName.substring(0,1).toUpperCase()
        let firstNameMaj=firstName.substring(0, 1).toUpperCase()
        lastName=lastNameMaj+lastName.substring(1).toLowerCase()
        firstName=firstNameMaj+firstName.substring(1).toLowerCase()
        const payload = {
            lastName: lastName,
            firstName: firstName,
            birthDate: birthDate,
            gender: gender,
            lang: language,
            level: level,
            tShirt: tShirt,
            allowGoingHomeAlone: allowGoingHomeAlone,
            responsibleOfRetrieving: responsibleToRetrive,
            imageRight: imageRight
        }
        const id = localStorage.getItem('userid');
        await client.post("/" + id + "/children", payload, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const deleteChild = async (childId) => {
    try {
        const id = localStorage.getItem('userid');
        return await client.delete("/" + id + "/children/" + childId, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const deleteAccount = async (id) => {
    try {
        return await client.delete("/" + id, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const getMutualDocument = async (id) => {
    try {
        let res = await client.get("/" + id + "/mutual/", {
            headers: {'Authorization': localStorage.getItem('token')},
            responseType: 'arraybuffer'
        });
        let blob = new Blob([res.data], {type: 'application/pdf'});
        download(blob, 'mutual.pdf', 'application/pdf');
    } catch (e) {
       throw e;
    }
}

const getOwnedBrevets = async (id) => {
    try {
        const brevets = await client.get("/" + id + "/brevets", {headers: {'Authorization': localStorage.getItem('token')}});
        return brevets.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const getOrdersHistory = async (id) => {
    try {
        const orders = await client.get("/" + id + "/orders", {headers: {'Authorization': localStorage.getItem('token')}});
        return orders.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const updateAccount = async (id, lastName, firstName, phone, email, street, houseNumber, box,postalCode ,locality,birthDate, gender, level, tShirt, lang, password) => {
    try {
        const payload = {
            lastName: lastName,
            firstName: firstName,
            birthDate: birthDate,
            phone: phone,
            email: email,
            gender: gender,
            street: street,
            houseNumber: houseNumber,
            box:box,
            postalCode: postalCode,
            locality: locality,
            level: level,
            tShirt: tShirt,
            lang:lang,
            password: password,
        }
        let response = await client.put("/" + id, payload, {headers: {'Authorization': localStorage.getItem('token')}});
        return response
    }catch (e) {
        console.error(e);
        throw e;
    }
}
const updateChildAccount = async (parentId,id, firstName, lastName, street, houseNumber, box, postalCode, locality, birthdate, gender, level, tShirt, language) => {
    try {
        const payload = {
            lastName: lastName,
            firstName: firstName,
            birthDate: birthdate,
            gender: gender,
            street: street,
            houseNumber: houseNumber,
            box: box,
            postalCode: postalCode,
            locality: locality,
            level: level,
            tShirt: tShirt,
            lang: language,
        }
        let response = await client.put(parentId+"/children/" + id, payload, {headers: {'Authorization': localStorage.getItem('token')}});
        return response
    } catch (e) {
        console.error(e);
        throw e;
    }
}




export {
    addChild, getCurrentChildrens, deleteChild, getCurrentAccount, getAccount, getMutualDocument, getAllAccounts,deleteAccount,getOrdersHistory,getChildrens,updateAccount,updateChildAccount,getOwnedBrevets,getAllAccountsNames,getTeamMembers,getChildAccount
}
