import React from "react";
import Select from "react-select";

import SelectorLocation from "./SelectorLocation";

const SelectorUser = ({
                          userList,
                          selectedAccount,
                          locationList,
                          sectionList,
                          courseList,
                          courseFiltered,
                          handleChildSelect,
                          handleSectionSelect,
                          handleCourseSelect,
                          handleAddToCart,
                          handleSemesterSelect,
                          handleLocationSelect,
                          selectedCourse,
                          selectedSection,
                          selectedLocation,
                          onUpdate,
                          error,
                          setError,

                      }) => {

    return (
        <div className="flex flex-col items-center justify-center m-5">
            <h1 className="text-3xl font-bold mb-2">Veuillez sélectionner un compte</h1>
            <div className="flex flex-col items-center justify-center ">
                <div className=" h-screen w-full">
                    <Select className="text-black " options={userList} onChange={(e) => {
                        handleChildSelect(e.value)
                    }}/>
                    {selectedAccount &&
                        <SelectorLocation
                            sectionList={sectionList}
                            locationList={locationList}
                            selectedAccount={selectedAccount}
                            handleSectionSelect={handleSectionSelect}
                            courseList={courseList} handleCourseSelect={handleCourseSelect}
                            courseFiltered={courseFiltered}
                            handleAddToCart={handleAddToCart} handleLocationSelect={handleLocationSelect}
                            selectedLocation={selectedLocation} selectedSection={selectedSection}
                            selectedCourse={selectedCourse}
                            onUpdate={onUpdate}
                            error={error}
                            setError={setError}
                        />

                    }
                </div>
            </div>
        </div>
    );
}

export default SelectorUser;