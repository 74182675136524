import axios from "axios"
import {TOKEN, url} from "../utils/constants";

const client = axios.create({
    baseURL: url + "auth"
})


const getRight = async () => {
    try {
        const response = await client.get("/", {headers: {'Authorization': localStorage.getItem('token')}})
        return response.data;
    } catch (e) {
        return e.response.status
    }
}

const login = async (email, password) => {
    try {
        const payload = {
            credentials: email,
            password: password,
        }
        const token = await client.post("/login", payload);
        localStorage.setItem('userid', token.data.id)
        localStorage.setItem(TOKEN, token.data.jwt)
        return token.data;
    } catch (e) {
        if (e.message !== undefined) {
            if (e.message === 'Network Error') {
                return e.code
            }
        }
        return e.response.status
    }
}


const register = async (lastName, firstName, gender, birthDate, street, houseNumber, box, postcode, locality, phone, level, tShirt, email, password,imageAuthorization) => {
    let lastNameMaj=lastName.substring(0,1).toUpperCase()
    let firstNameMaj=firstName.substring(0, 1).toUpperCase()
    lastName=lastNameMaj+lastName.substring(1).toLowerCase()
    firstName=firstNameMaj+firstName.substring(1).toLowerCase()
    const payload = {
        lastName: lastName.substring(),
        firstName: firstName.toLowerCase(),
        gender: gender,
        birthDate: birthDate,
        street: street.toLowerCase(),
        houseNumber: houseNumber,
        box: box,
        postalCode: postcode,
        locality: locality.toLowerCase(),
        level: level,
        tShirt: tShirt,
        phone: phone,
        email: email,
        password: password,
        imageRight: imageAuthorization
    }

    const token = await client.post("/register", payload)
    localStorage.setItem('userid', token.data.id)
    localStorage.setItem(TOKEN, JSON.stringify(token.data.jwt))
    return token.data
}

const paswordForgot = async (email) => {
    const payload = {
        email: email
    }
    try {
        const token = await client.post("/password/forgotten", payload)
        return token.data;
    } catch (e) {
        if (e.message !== undefined) {
            if (e.message === 'Network Error') {
                return e.code
            }
        }
        return e.response.status
    }
}

const updatePassword = async (newPassword) => {
    try{
        const payload = {
            password: newPassword
        }
        await client.put( "/password", payload, {headers: {'Authorization': localStorage.getItem('token')}});

    } catch (e) {
        console.error(e);
        throw e;
    }
}



export {
    login,
    register,
    getRight,
    paswordForgot,
    updatePassword
}
