/* eslint-disable react/display-name, jsx-a11y/click-events-have-key-events */
import {Navigation} from "react-minimal-side-navigation";
import {useLocation, useNavigate} from "react-router-dom";
//import {FaMountain} from "react-icons/fa";
import {FaHome, FaMountain} from "react-icons/fa";
//import {GrUserAdmin, GrUserSettings} from "react-icons/gr";
import {
  GiArchiveRegister,
  GiDiploma,
  GiHamburgerMenu,
  GiMountainClimbing
} from "react-icons/gi";
import {BsListNested} from "react-icons/bs";
import React, {useState} from "react";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
//import {FiEdit} from "react-icons/fi";
import {RiTeamFill} from "react-icons/ri";
import Disconnect from "./Disconnect";
import CartComponent from "../Cart/CartComponent";

export const NavSidebarTeamMember = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);


    return (
        <React.Fragment>
            {/* Sidebar Overlay */}
            <div
                onClick={() => setIsSidebarOpen(false)}
                className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${isSidebarOpen ? "block" : "hidden"}`}
            />


            <div>
                <button
                    className="btn-menu"
                    onClick={() => setIsSidebarOpen(true)}
                    type="button"
                >
                    <GiHamburgerMenu name="burger" className="w-10 h-6"/>
                </button>
            </div>

            <CartComponent/>

            {/* Sidebar */}
            {isSidebarOpen &&
                <div
                    className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${isSidebarOpen ? "ease-out translate-x-0" : "ease-in -translate-x-full"}`}
                >
                    <div className="flex items-center justify-center mt-10 text-center py-6">
          <span className="mx-2 text-2xl font-semibold text-black">
              Menu EV
          </span>

                    </div>

                    <Navigation
                        activeItemId={location.pathname}
                        onSelect={({itemId}) => {
                            navigate(itemId);
                        }}
                        items={[
                            {
                                title: "Home", itemId: "/team-member/home", // Optional
                                elemBefore: () => <FaHome/>
                            },
                            {
                                title: "Inscriptions",
                                itemId: "/team-member/inscriptions",
                                elemBefore: () => <GiArchiveRegister/>,
                                subNav: [{
                                    title: "Indoor", itemId: "/team-member/inscriptions/indoor", // Optional
                                    elemBefore: () => <GiMountainClimbing/>
                                },{
                                    title: "Outdoor", itemId: "/team-member/inscriptions/outdoor", // Optional
                                    elemBefore: () => <FaMountain/>
                                }
                                    // ,{
                                    //     title: "Outdoor",
                                    //     itemId: "/inscriptions/outdoor",
                                    //     elemBefore: () => <FaMountain/>
                                    // }
                                ]
                            },
                            {
                                title: "Team Panel",
                                itemId: "/team-member/team-panel",
                                elemBefore: () => <RiTeamFill/>,
                                subNav: [{
                                    title: "cours List", itemId: "/team-member/team/course-list", // Optional
                                    elemBefore: () => <BsListNested color="black"/>
                                }// , {
                                    //     title: "Outdoor",
                                    //     itemId: "/inscriptions/admin/indoor",
                                    //     // Optional
                                    //     elemBefore: () => <><FiEdit/><GiMountainClimbing/></>
                                    // }

                                ]
                            }
                        ]}
                    />
                    <div className="absolute bottom-0 w-full my-8">
                        <div className="items-center justify-center  text-center  ">
                            <a className="text-black underline  hover:text-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1"
                               href={"https://www.ev-club.be/"}>retour à l'accueil du site</a>
                        </div>
                        <Disconnect/>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};
