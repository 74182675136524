import Select from "react-select";
import React from "react";
import SelectorStage from "./SelectorStage";


const SelectorUser = ({
                          handleChildSelect,
                          userList,
                          selectedAccount,
                          selectedIndoorStageList,
                          handleSelectedStage,
                          selectedStage,
                          handleAddToCart,onUpdate,
                          error,setError
                      }) => {


    return (
        <div className="flex flex-col items-center justify-center m-5">
            <h1 className="text-3xl font-bold mb-2">Veuillez sélectionner un compte</h1>
            <div className="flex flex-col items-center justify-center ">
                <div className=" h-screen w-full">
                    <Select className="text-black " options={userList} onChange={(e) => {
                        handleChildSelect(e.value)
                    }}/>
                    {selectedAccount &&
                        <SelectorStage
                            selectedAccount={selectedAccount}
                            selectedStageList={selectedIndoorStageList}
                            handleSelectedStage={handleSelectedStage}
                            selectedStage={selectedStage}
                            handleAddToCart={handleAddToCart}
                            onUpdate={onUpdate}
                            error={error}
                            setError={setError}
                        />
                    }
                </div>
            </div>
        </div>
    );

}

export default SelectorUser;