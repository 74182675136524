import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
    baseURL: url + "inscriptions"
})

const inscriptions_course = async (accountId, courseId) => {
    try {
        const payload = {
            "accountId": accountId,
            "courseId": courseId
        }
        return await client.post("/courses", payload, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        console.error(e);
        throw e;
    }
}

const delete_inscriptions_course = async (inscriptionId, courseId) => {
    try {
        return await client.delete("/" + inscriptionId + "/courses/" + courseId, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        console.error(e);
    }
}

const manual_inscription_course = async (accountId, courseId) => {
    try {
        const payload = {
            "accountId": accountId,
            "courseId": courseId
        }
        return await client.post("/courses/manual", payload, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
       throw e
    }
}

const change_course=async (inscriptionId, courseId)=>{
    try {
        const payload = {
            "inscriptionId": inscriptionId,
            "newCourseId": courseId
        }
        return await client.post("/changeCourse", payload, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        throw e
    }
}

export {
    inscriptions_course, delete_inscriptions_course,manual_inscription_course,change_course
}
