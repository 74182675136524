import DataTable from "react-data-table-component";
import {DataTableTheme} from "../DataTableComponents/DataTableTheme";
import React, {useState} from "react";
import {getInscriptionsFromOrder} from "../../services/OrderService";
import {BarLoader} from "react-spinners";

const ListInscriptions = ({ordersList}) => {
//use state and use effect to collect all the inscription from the account and their childrens
    const [currentRow, setCurrentRow] = useState(null);
    const [inscriptionsContent, setInscriptionsContent] = useState()

    let orderColumn = {
        columns: [

            {
                name: 'date',
                selector: row => row.order_date,
                sortable: true
            },
            {
                name: 'total',
                selector: row => row.total_price +'€',
                sortable: true
            },
            {
                name: 'status',
                selector: row => row.status,
                sortable: true
            }
        ]
    }

    let inscriptionColumn = {
        columns: [
            {
                name: 'compte',
                selector: row => row.inscrit,
            }, {
                name: 'start',
                selector: row => row.begin_date,
                grow: 3
            }, {
                name: 'end',
                selector: row => row.end_date,
                grow: 3
            }, {
                name: 'id inscirption',
                selector: row => row.id,
            }, {
                name: 'type',
                selector: row => row.type,
                grow: 2
            },
            {
                name: 'description',
                selector: row => row.description,
                grow: 5
            },
            {
                name: 'prix',
                selector: row => row.price,
            }, {
                name: 'reduction',
                selector: row => row.reduction,
            }]
    }

    const ExpandedComponent = () => {
        return (<pre className='w-max'><DataTable columns={inscriptionColumn.columns} data={inscriptionsContent}
                                                  showGridlines
                                                  progressComponent={<BarLoader color="#e44c65" size='100' />}
                                                  theme="TableEV"
                                                  highlightOnHover/></pre>);
    }

    const loadInscriptionsFromOrder = async (row) => {
        setCurrentRow(row)
        setInscriptionsContent([])
        const inscriptions = await getInscriptionsFromOrder(row.id)
        let inscriptionsContainer = []
        // collect all inscription from: inscriptions.inscriptionsCourses,inscriptions.inscriptionsTrainingCourses,inscriptions.inscriptionsSubscriptions,inscription.inscriptionsMembers,inscription.inscriptionsMembersCab
        if (inscriptions.inscriptionsCourses.length > 0) {
            inscriptions.inscriptionsCourses.forEach((inscr) => {
                inscr.inscription.inscrit = inscr.account.first_name
                inscr.inscription.description = inscr.course.week_day +' de '+ inscr.course.begin_time.substring(0,5)
                inscriptionsContainer.push(inscr.inscription)

            })
        }
        if (inscriptions.inscriptionsTrainingCourses.length > 0) {
            inscriptions.inscriptionsTrainingCourses.forEach((inscr) => {
                inscr.inscription.inscrit = inscr.account.first_name
                inscr.inscription.description = inscr.trainingCourse.description
                inscriptionsContainer.push(inscr.inscription)
            })
        }
        if (inscriptions.inscriptionsSubscriptions.length > 0) {
            inscriptions.inscriptionsSubscriptions.forEach((inscr) => {
                inscr.inscription.inscrit = inscr.account.first_name
                inscriptionsContainer.push(inscr.inscription)
            })
        }
        if (inscriptions.inscriptionsMembers.length > 0) {
            inscriptions.inscriptionsMembers.forEach((inscr) => {
                inscr.inscription.inscrit = inscr.account.first_name
                inscriptionsContainer.push(inscr.inscription)
            })
        }
        if (inscriptions.inscriptionsMembersCab.length > 0) {
            inscriptions.inscriptionsMembersCab.forEach((inscr) => {
                inscr.inscription.inscrit = inscr.account.first_name
                inscr.inscription.type = inscr.inscription.type + '_' + inscr.memberTypeCab.name
                inscriptionsContainer.push(inscr.inscription)
            })
        }
        //if no inscriptions set inscriptionsContainer to []
        if (inscriptions.inscriptionsMembersCab.length === 0 && inscriptions.inscriptionsMembers.length === 0 && inscriptions.inscriptionsSubscriptions.length === 0 && inscriptions.inscriptionsTrainingCourses.length === 0 && inscriptions.inscriptionsCourses.length === 0) {
            inscriptionsContainer = [{
                id: "created",
                type: "unfinished",
                begin_date: "aucun",
                end_date: "aucun",
                inscription_date: "aucun"

            }]
        }
        //for each inscription reformat end,begin and insciption date
        inscriptionsContainer.forEach(inscription => {
            if (inscription.begin_date) {
                inscription.begin_date = inscription.begin_date.split("T")[0].split("-").reverse().join("/")
            }
            if (inscription.end_date) {
                inscription.end_date = inscription.end_date.split("T")[0].split("-").reverse().join("/")
            }
            if (inscription.inscription_date) {
                inscription.inscription_date = inscription.inscription_date.split("T")[0].split("-").reverse().join("/")
            }
            //if type ==="trainingCourse" replace by stage, if type === "subscription" replace by abonnement
            if (inscription.type === "training_course") {
                inscription.type = "stage"
            }
            if (inscription.type === "subscription") {
                inscription.type = "abonnement"
                inscription.description = "abonnement"

            }
            if (inscription.type === "member_cab_indoor") {
                inscription.type = "cab indoor"
            }
            if (inscription.type === "membre_cab_outdoor") {
                inscription.type = "cab outdoor"
            }
            if (inscription.type === "member") {
                inscription.type = "membre"
                inscription.description = "cotisation"
            }
        })
        setInscriptionsContent(inscriptionsContainer)
    }



    DataTableTheme()

    return (
        <div className="overflow-auto overflow-scroll">
            <h1>Historique de commandes</h1>
            {ordersList.length === 0 ?
                <p>Aucune inscription</p>
                :
                <DataTable
                    columns={orderColumn.columns}
                    data={ordersList}
                    expandableRows
                    expandableRowExpanded={(row) => (row === currentRow)}
                    onRowClicked={(row) => setCurrentRow(row)}
                    expandableRowsComponent={ExpandedComponent}
                    onRowExpandToggled={(bool, row) => loadInscriptionsFromOrder(row)}
                    showGridlines
                    pagination
                    highlightOnHover
                    progressComponent={<BarLoader color="#e44c65" size='100' />}
                    theme="TableEV"
                    paginationRowsPerPageOptions={[1, 5, 10, 20, 25, 50, 100]}
                />
            }

        </div>
    );

}
export default ListInscriptions;