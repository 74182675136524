import React from "react";
import Select from "react-select";
import SelectorCourse from "./SelectorCourse";

const SelectorSection = ({
                             sectionList,
                             courseList,
                             courseFiltered,
                             handleSectionSelect,
                             handleCourseSelect,
                             handleAddToCart,
                             selectedCourse,
                             selectedSection,
                             selectedLocation,
                             onUpdate,
                             error,
                             setError,

                         }) => {

    return (
        <div>
            <div className="flex flex-col items-center justify-center m-2">
                <h1 className="text-3xl font-bold mb-2">Veuillez
                    sélectionner
                    une section</h1>
                <div className="flex flex-col items-center justify-center ">
                    <div className=" h-max w-full">
                        <Select className="text-black mb-2"
                                options={sectionList}
                                onChange={(e) => {
                                    handleSectionSelect(e.value)
                                }
                                }/>
                        {selectedSection &&
                            <>
                                <SelectorCourse courseList={courseList} handleCourseSelect={handleCourseSelect}
                                                courseFiltered={courseFiltered} onUpdate={onUpdate}
                                                selectedCourse={selectedCourse} handleAddToCart={handleAddToCart}
                                                error={error}
                                                setError={setError}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>);

}

export default SelectorSection;