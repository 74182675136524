import React, {useEffect, useMemo, useState} from 'react';
import * as apiCourses from "../../services/CourseService";
import * as apiLocation from "../../services/LocationService";
import * as apiSection from "../../services/SectionService"
import AddCourseModal from "../../components/Modals/AddCourseModal";
import AddLocationModal from "../../components/Modals/AddLocationModal";
import AddSectionModal from "../../components/Modals/AddSectionModal";
import {DashboardLayout} from "../../components/Layout/Layout";
import {visibilityOfCourse, visibilityOfLocation} from "../../services/CourseService";
import {getCotisation} from "../../services/CotisationService";
import EditCotisationModal from "../../components/Modals/EditCotisationModal";
import {ifExpiredDisconnect} from "../../utils/toolbox";
import EditCourseModal from "../../components/Modals/EditCourseModal";
import {DataTableTheme} from "../../components/DataTableComponents/DataTableTheme";
import FilterComponent from "../../components/DataTableComponents/FilterComponent";

import DataTable from "react-data-table-component";
import {BarLoader} from "react-spinners";
import EditSectionModal from "../../components/Modals/EditSectionModal";
import EditLocationModal from "../../components/Modals/EditLocationModal";
import ShowStudentsModal from "../../components/Modals/ShowStudentsModal";


const AdminIndoor = ({right}) => {
    //_______________________________STATES AND EFFECTS_________________________________________________________________
    const [courseModal, setCourseModal] = useState(false);
    const [addSectionModal, setAddSectionModal] = useState(false);
    const [editSectionModal, setEditSectionModal] = useState(false);
    const [addLocationModal, setAddLocationModal] = useState(false);
    const [editLocationModal, setEditLocationModal] = useState(false);
    const [cotisationModal, setCotisationModal] = useState(false);
    const [editCourseModal, setEditCourseModal] = useState(false);
    const [showModalCourseToShowDetails,setShowModalCourseToShowDetails] = useState(false);
    const [selectedCotisationToEdit, setSelectedCotisationToEdit] = useState()
    const [selectedNameCotisationToEdit, setSelectedNameCotisationToEdit] = useState()
    const [selectedCourseToEdit, setSelectedCourseToEdit] = useState();
    const [courses, setCourses] = useState([]);
    const [locations, setLocations] = useState([]);
    const [locationToEdit, setLocationToEdit] = useState();
    const [locationsUnformated, setLocationsunformated] = useState([]);
    const [sections, setSections] = useState([]);
    const [sectionsUnformated, setSectionsUnformated] = useState([]);
    const [selectedCourseToShowDetails,setSelectedCourseToShowDetails] = useState()
    const [selectedSectionToEdit, setSelectedSectionToEdit] = useState();
    const [cotisationsIndoorUnformated, setCotisationIndoorUnformated] = useState([]);
    const [update, setUpdate] = useState(false);
    const [showLocationsList, setShowLocationsList] = useState(false)
    const [showSectionsList, setShowSectionsList] = useState(false)
    const [showCotisationList, setShowCotisationList] = useState(false)
    const [error, setError] = useState()
    const [pending, setPending] = React.useState(true);
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);


    useEffect(() => {
        let array = []
        ifExpiredDisconnect()
        const getdatas = async () => {
            const output = await Promise.all([apiCourses.getCoursesList(), apiLocation.getLocationList(), apiSection.getSectionList(), getCotisation(),apiCourses.places()]);
            // courses
            //for each course replace the location_id by the location name and the section_id by the section description
            output[0].forEach(course => {
                let location = output[1].find(location => location.id === course.location_id)
                let section = output[2].find(section => section.id === course.section_id)
                let coursesWithPlaces = output[4].find(courseW => courseW.id === course.id)
                course.countInscriptions = coursesWithPlaces.countInscriptions
                course.location_id = location.name
                course.section_id = section.description
                course.horaire = course.begin_time.substring(0, 5) + " à " + course.end_time.substring(0, 5)
                course.ref = course.week_day.substring(0, 2)+course.begin_time.substring(0, 2)+'-'+section.description.substring(0,5)+section.min_age+'-'+course.begin_date.substring(0,4)
                if (course.reserved) {
                    course.visible = <div
                        className="px-2 text-xs leading-5 font-semibold rounded-full bg-red-100 text-blue-800">invisible</div>
                    course.hide = <button onClick={() => {
                        handleHideCourse(course.id, !course.reserved)
                    }}
                                          className=" border rounded p-1 border-white text-white m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-white hover:text-black">
                        Afficher
                    </button>
                } else {
                    course.visible = <div
                        className="px-2 text-xs leading-5 font-semibold rounded-full bg-green-100 text-blue-800">visible</div>
                    course.hide = <button
                        onClick={() => {
                            handleHideCourse(course.id, !course.reserved)
                        }}
                        className=" border rounded p-1 border-white text-white m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-white hover:text-black"
                    >
                        Masquer
                    </button>
                }
                course.modify = <button onClick={() => {
                    onEditCourse(course.id, course)
                }}
                                        className=" border rounded p-1 border-pinkEv text-pinkEv m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">modifier</button>
                course.delete = <button onClick={() => {
                    DeleteCourse(course.id)
                }}
                                        className=" border rounded p-1 border-white text-white m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-white hover:text-black">supprimer</button>
                course.dupliquer = <button onClick={() => {
                    DuplicateCourse(course.id)
                }}
                                           className=" border rounded p-1 border-white text-white m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-white hover:text-black">Dupliquer</button>
            });

            setCourses(output[0]);
            // location
            setLocationsunformated(output[1]);
            array = []
            output[1].forEach(location => {
                array.push({value: location.id, label: location.name})
            })
            setLocations(array);
            // section
            setSectionsUnformated(await output[2]);
            array = []
            output[2].forEach(section => {
                array.push({value: section.id, label: section.description})
            })
            setSections(array);
            setCotisationIndoorUnformated(output[3])
            await setPending(false);
        }
        getdatas();

    }, [update]);

    //_________________________________HANDLERS___________________________________________________________________
    const onEditCourse = async (id, data) => {
        //load the course data and open AddCourseModal with props predefined
        const course = await apiCourses.getCourse(id);
        setEditCourseModal(!editCourseModal)
        setSelectedCourseToEdit(course)
        setUpdate(!update);
    }
    const onUpdate = () => {
        setUpdate(!update)
    }
    const DuplicateCourse = async (id) => {
        await apiCourses.duplicateCourse(id);
        setUpdate(!update);
    }
    const DeleteCourse = async (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce cours ?")) {
            await apiCourses.deleteCourse(id);
            onUpdate();
        }
    }
    const onCancelEditCourseModal = () => {
        setEditCourseModal(false);
    }
    const onCancelCourseModal = () => {
        setCourseModal(false);
    }
    const onCancelEditSectionModal = () => {
        setEditSectionModal(false);
    }
    const onCancelAddSectionModal = () => {
        setAddSectionModal(false);
    }
    const onCancelAddLocationModal = () => {
        setAddLocationModal(false);
    }
    const onCancelEditLocationModal = () => {
        setEditLocationModal(false);
    }
    const onCancelCotisationModal = () => {
        setCotisationModal(false);
    }
    const onDeleteLocation = async (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cette location ?")) {
            //delete the location
            try {
                const res = await apiLocation.deleteLocation(id)
                if (res) {
                    if (res.response.data.message.startsWith("Cannot delete location with id=")) {
                        setError('impossible de supprimer un lieu déjà utilisé par des cours')
                    } else {
                        setError(res.response.data.message)
                    }
                }
                onUpdate()
            } catch (e) {
                setError(e.response.data.message)
            }
        }
    }
    const onDeleteSection = async (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cette section ?")) {
            //delete the section
            try {
                const res = await apiSection.deleteSection(id)
                if (res) {
                    if (res.response.data.message.startsWith("Could not delete section with id=")) {
                        setError('impossible de supprimer une séction utilisée par des cours')
                    } else {
                        setError(res.response.data.message)
                    }
                }
                onUpdate()
            } catch (e) {
                console.log(e)
            }
        }
    }

    const handleHideLocationCourses = async (id) => {
        await visibilityOfLocation(id, true)
        onUpdate()
    }

    const handleHideCourse = async (id, visibility) => {
        await visibilityOfCourse(id, visibility)
        onUpdate()
    }

    const handleSetShowDetailsCourse = row => {
        setSelectedCourseToShowDetails(row.id)
        setShowModalCourseToShowDetails(true)
    }
    const handleShowStudentModal = () => {
        setShowModalCourseToShowDetails(!selectedCourseToShowDetails)
    }
    //_____________________________________________________RENDERING___________________________________________________
    const formatData = (data) => {
        let table = {
            columns: [
                {
                    name: 'ref',
                    selector: row => row.ref,
                    sortable: true,
                    grow:2

                },
                {
                    name: 'section',
                    selector: row => row.section.description,
                    sortable: true,
                    grow:3
                },
                {
                    name: 'jour',
                    selector: row => row.week_day,
                    sortable: true,
                },
                {
                    name: 'horaire',
                    selector: row => row.horaire,
                    sortable: true,
                    grow:2
                },
                {
                    name: 'places',
                    selector: row => row.max_capacity-row.countInscriptions+'/'+row.max_capacity,
                    sortable: true,
                },
                {
                    name: 'prix',
                    selector: row => row.price,
                    sortable: true,
                },
                {
                    name: 'visible',
                    selector: row => row.visible,
                    sortable: true,
                },
                {
                    name: 'modify',
                    selector: row => row.modify
                },
                {
                    name: 'delete',
                    selector: row => row.delete
                },{
                    name: 'dupliquer',
                    selector: row => row.dupliquer
                },
                {
                    name: 'hide',
                    selector: row => row.hide
                }

            ],
            rows: data
        }
        return table
    }

    const cotisationNameMapper=(cotisation)=>{
            switch(cotisation.name){
                case 'indoor':
                    return 'indoor(-23)'
                case 'indoor23':
                    return 'indoor(+23)'
                case 'outdoor':
                    return 'outdoor(-23)'
                case 'outdoor23':
                    return 'outdoor(+23)'
                default:
                    return cotisation.name
            }
    }

    DataTableTheme()
    const filteredItems = courses.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    //---------------------------------------------------DATA TABLE----------------------------------------------------
    return (
        <DashboardLayout>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Admin Indoor</h2>
            <div className=" ">
                <div className=" mt-14 ml-10 mr-10 content-center">
                    <button
                        onClick={() => {
                            setCourseModal(true)
                        }}
                        className=" border font-bold rounded p-1 border-pinkEv text-pinkEv m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                        ajouter un cours
                    </button>
                    {showSectionsList === false ?
                        <button
                            onClick={() => {
                                setShowSectionsList(!showSectionsList)
                            }}
                            className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                            afficher la liste des sections
                        </button>
                        :
                        <button
                            onClick={() => {
                                setShowSectionsList(!showSectionsList)
                            }}
                            className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                            cacher la liste des sections
                        </button>
                    }
                    {showLocationsList === false ?
                        <button
                            onClick={() => {
                                setShowLocationsList(!showLocationsList)
                            }}
                            className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                            afficher la liste des lieux
                        </button>
                        :
                        <button
                            onClick={() => {
                                setShowLocationsList(!showLocationsList)
                            }}
                            className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                            cacher la liste des lieux
                        </button>
                    }
                    {showCotisationList === false ?
                        <button
                            onClick={() => {
                                setShowCotisationList(!showCotisationList)
                            }}
                            className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                            afficher la liste des cotisations
                        </button>
                        :
                        <button
                            onClick={() => {
                                setShowCotisationList(!showCotisationList)
                            }}
                            className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                            cacher la liste des cotisations
                        </button>
                    }
                    <div>
                        {error && <p className="text-red-500 m-3"> Error:{error}</p>}
                    </div>
                    {showSectionsList &&
                        //display a table of the categories and a button to remove a category for each tuple
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <h2>sections</h2>
                            <table className=' text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"'>
                                <thead
                                    className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                                <tr>
                                    <th scope="col" class="px-6 py-3">id</th>
                                    <th scope="col" class="px-6 py-3">description</th>
                                    <th scope="col" className="px-6 py-3">age min</th>
                                    <th scope="col" className="px-6 py-3">age max</th>
                                    <th scope="col" className="px-6 py-3">level</th>
                                    <th scope="col" className="px-6 py-3">editer</th>
                                    <th scope="col" className="px-6 py-3">supprimer</th>
                                </tr>
                                </thead>
                                <tbody>
                                {sectionsUnformated.map(section =>
                                    <tr className="bg-gray-300 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600"
                                        key={section.id}>
                                        <td className=" px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{section.id}</td>
                                        <td className=" px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{section.description}</td>
                                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{section.min_age}</td>
                                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{section.max_age}</td>
                                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{section.level}</td>
                                        <td className='px-6 py-4'>
                                            <button className="btn btn-danger p-1 inline-flex font-semibold "
                                                    onClick={() => onDeleteSection(section.id)}
                                            >Supprimer
                                            </button>
                                        </td>
                                        <td className='px-6 py-4'>
                                            <button className="btn btn-danger p-1 inline-flex font-semibold "
                                                    onClick={() => {
                                                        setEditSectionModal(true)
                                                        setSelectedSectionToEdit(section)
                                                    }}
                                            >Editer
                                            </button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <button
                                onClick={() => {
                                    setAddSectionModal(true)
                                }}
                                className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                                ajouter une section de cours
                            </button>
                        </div>
                    }
                    {showLocationsList &&
                        //display a table of the categories and a button to remove a category for each tuple
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <h2>Lieux</h2>
                            <table className=' text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"'>
                                <thead
                                    className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                                <tr>
                                    <th scope="col" class="px-6 py-3">id</th>
                                    <th scope="col" class="px-6 py-3">nom</th>
                                    <th scope="col" className="px-6 py-3">address</th>
                                    <th scope="col" className="px-6 py-3">code postal</th>
                                    <th scope="col" className="px-6 py-3">editer</th>
                                    <th>supprimer</th>
                                    <th>masquer les cours</th>
                                </tr>
                                </thead>
                                <tbody>
                                {locationsUnformated.map(location =>
                                    <tr className="bg-gray-300 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600"
                                        key={location.id}>
                                        <td className=" px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{location.id}</td>
                                        <td className=" px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{location.name}</td>
                                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{location.street + location.house_number + ' ' + location.box + ' ' + location.postal_code + ' ' + location.locality}</td>
                                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{location.postal_code}</td>
                                        <td className='px-6 py-4'>
                                            <button className="btn btn-danger p-1 inline-flex font-semibold "
                                                    onClick={() => {
                                                        setEditLocationModal(true)
                                                        setLocationToEdit(location)
                                                    }}
                                            >Editer
                                            </button>
                                        </td>
                                        <td className='px-6 py-4'>
                                            <button className="btn btn-danger p-1 inline-flex font-semibold "
                                                    onClick={() => onDeleteLocation(location.id)}
                                            >Supprimer
                                            </button>
                                        </td>
                                        <td className='px-6 py-4'>
                                            <button className="btn btn-danger p-1 inline-flex font-semibold "
                                                    onClick={() => handleHideLocationCourses(location.id)}
                                            >tout masquer
                                            </button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <button
                                onClick={() => {
                                    setAddLocationModal(true)
                                }}
                                className=" border rounded p-1 border-pinkEv text-white font-bold m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white">
                                ajouter un lieu de cours
                            </button>
                        </div>
                    }
                    {showCotisationList &&
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <h2>Cotisations</h2>
                            <table className=' text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"'>
                                <thead
                                    className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                                <tr>
                                    <th scope="col" className="px-6 py-3">id</th>
                                    <th scope="col" className="px-6 py-3">nom</th>
                                    <th scope="col" className="px-6 py-3">prix</th>
                                    <th>éditer</th>
                                </tr>
                                </thead>
                                <tbody>
                                {cotisationsIndoorUnformated.map(cotisation =>
                                    <tr className="bg-gray-300 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600"
                                        key={cotisation.id}>
                                        <td className=" px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{cotisation.id}</td>
                                        <td className=" px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{cotisationNameMapper(cotisation) }</td>
                                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{cotisation.price}</td>
                                        <td className='px-6 py-4'>
                                            <button className="btn btn-danger p-1 inline-flex font-semibold "
                                                    onClick={() => {
                                                        setSelectedCotisationToEdit(cotisation.id)
                                                        setSelectedNameCotisationToEdit(cotisation.name)
                                                        setCotisationModal(true)
                                                    }
                                                    }

                                            >éditer
                                            </button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>

                    }

                    <div className=" overflow-auto shadow-md sm:rounded-lg">

                        <DataTable columns={formatData(courses).columns}
                                   data={filteredItems}
                                   showGridlines
                                   theme="TableEV"

                                   highlightOnHover
                                   subHeader
                                   onRowClicked={handleSetShowDetailsCourse}
                                   progressPending={pending}
                                   progressComponent={<BarLoader color="#e44c65" size='100' />}
                                   subHeaderComponent={subHeaderComponent}
                        />
                    </div>
                </div>
            </div>
            {editCourseModal &&
                <EditCourseModal onCancel={onCancelEditCourseModal} onUpdate={onUpdate}
                                 sectionList={sections} locationList={locations}
                                 course={selectedCourseToEdit}/>
            }
            {courseModal &&
                <AddCourseModal onCancel={onCancelCourseModal} sectionList={sections} locationList={locations}
                                onUpdate={onUpdate}/>
            }
            {editSectionModal &&
                <EditSectionModal onCancel={onCancelEditSectionModal} onUpdate={onUpdate} section={selectedSectionToEdit} />
            }
            {addSectionModal &&
                <AddSectionModal onCancel={onCancelAddSectionModal} onUpdate={onUpdate} />
            }
            {editLocationModal &&
                <EditLocationModal onCancel={onCancelEditLocationModal} onUpdate={onUpdate} location={locationToEdit}/>
            }
            {addLocationModal &&
                <AddLocationModal onCancel={onCancelAddLocationModal} onUpdate={onUpdate}/>
            }
            {cotisationModal &&
                <EditCotisationModal onCancel={onCancelCotisationModal} onUpdate={onUpdate}
                                     id={selectedCotisationToEdit} name={selectedNameCotisationToEdit}/>
            }
            {showModalCourseToShowDetails &&
                <ShowStudentsModal selectedCourse={selectedCourseToShowDetails} onCancel={handleShowStudentModal}/>
            }
        </DashboardLayout>
    )
}

export default AdminIndoor;
