import React from "react";
import Select from "react-select";
import SelectorSection from "./SelectorSection";


const SelectorLocation = ({
                              selectedAccount,
                              locationList,
                              sectionList,
                              courseList,
                              courseFiltered,
                              handleSectionSelect,
                              handleCourseSelect,
                              handleAddToCart,
                              handleLocationSelect,
                              selectedCourse,
                              selectedSection,
                              selectedLocation,
                              onUpdate,
                              error,
                              setError,
                          }) => {
    return (
        <div className="flex flex-col items-center justify-center m-2">
            <h1 className="text-3xl font-bold mb-2">Veuillez sélectionner un une salle</h1>
            <div className="flex flex-col items-center justify-center ">
                <div className=" h-screen w-full">
                    <Select className="text-black mb-2" options={locationList} onChange={(e) => {
                        handleLocationSelect(e.value)

                    }
                    }/>
                    {selectedLocation &&
                        <>
                            {(selectedAccount && sectionList.length > 0 && courseList.length > 0) ?
                                <SelectorSection sectionList={sectionList} selectedSection={selectedSection}
                                                 handleSectionSelect={handleSectionSelect} onUpdate={onUpdate}
                                                 courseList={courseList} handleCourseSelect={handleCourseSelect}
                                                 handleAddToCart={handleAddToCart} selectedCourse={selectedCourse} courseFiltered={courseFiltered}
                                                 error={error}
                                                 setError={setError}
                                />
                                :
                                <div className="flex flex-col items-center justify-center ">
                                    <div className=" h-max ">
                                        <h1 className="text-2xl font-bold text-red-600 mb-5">Aucuns
                                            cours
                                            disponible actuellement </h1>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default SelectorLocation;