import React, {useContext} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import AuthenticationContext from "../contexts/AuthenticationContext";
import LandingPage from "./Default/Landing";
import Register from "./Default/Register";
import Login from "./Default/Login";
import HomePage from "./User/Home";
import Settings from "./User/Settings";
import Inscriptions from "./User/Inscriptions";
import MembersPage from "./Member/Members";
import Indoors from "./User/Indoors";
import Outdoors from "./User/Outdoors";
import TeamCourseList from "./TeamMember/TeamCourseList";
import TeamPanel from "./TeamMember/TeamPanel";
import TeamsPage from "./TeamMember/Teams";
import AdminMemberList from "./Admin/AdminMemberList";
import AdminInscriptionsList from "./Admin/AdminInscriptionsList";
import AdminTeamList from "./Admin/AdminTeamList";
import AdminPanel from "./Admin/AdminPanel";
import AdminIndoor from "./Admin/AdminIndoor";
import AdminOutdoor from "./Admin/AdminOutdoor";
import AdminLayout from "./Admin/AdminLayout";
import UserLayout from "./User/UserLayout";
import DefaultLayout from "./Default/DefaultLayout";
import TeamMemberLayout from "./TeamMember/TeamMemberLayout";
import PaymentCheckoutPass from "./Default/PaymentCheckout-pass";
import PaymentCheckoutFail from "./Default/PaymentCheckout-fail";
import AdminInscriptionStage from "./Admin/AdminInscriptionStage";
import ServerInMaintenance from "./Default/ServerInMaintenance";
import AdminSubscription from "./Admin/AdminSubscription";
import Forgot from "./Default/ForgotAccess";
import AdminBrevet from "./Admin/AdminBrevets";
import TeamBrevets from "./TeamMember/TeamBrevets";


const Routers = () => {
    const {right} = useContext(AuthenticationContext);

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<DefaultLayout right={right}/>}>
                    <Route path="/" exact element={<LandingPage/>}/>
                    <Route path="/register" exact element={<Register/>}/>
                    <Route path="/login" exact element={<Login/>}/>
                    <Route path="/forgot" exact element={<Forgot/>}/>
                    <Route path="/server_maintenance" exact element={<ServerInMaintenance/>}/>
                </Route>

                <Route element={<UserLayout right={right}/>}>
                    <Route path="/user/home" exact element={<HomePage/>}/>
                    <Route path="/user/settings" exact element={<Settings/>}/>
                    <Route path="/user/inscriptions" exact element={<Inscriptions/>}/>
                    <Route path="/user/inscriptions/members" exact element={<MembersPage/>}/>
                    <Route path="/user/inscriptions/indoor" exact element={<Indoors/>}/>
                    <Route path="/user/inscriptions/outdoor" exact element={<Outdoors/>}/>
                </Route>

                <Route element={<TeamMemberLayout right={right}/>}>
                    <Route path="/team-member/home" exact element={<HomePage/>}/>
                    <Route path="/team-member/settings" exact element={<Settings/>}/>
                    <Route path="/team-member/inscriptions" exact element={<Inscriptions/>}/>
                    <Route path="/team-member/inscriptions/members" exact element={<MembersPage/>}/>
                    <Route path="/team-member/inscriptions/indoor" exact element={<Indoors/>}/>
                    <Route path="/team-member/inscriptions/outdoor" exact element={<Outdoors/>}/>
                    <Route path="/team-member/team/course-list" exact element={<TeamCourseList/>}/>
                    <Route path="/team-member/team/brevets" exact element={<TeamBrevets/>}/>
                    <Route path="/team-member/team-panel" exact element={<TeamPanel/>}/>
                    <Route path="/team-member/another/teams" exact element={<TeamsPage/>}/>
                </Route>


                <Route element={<AdminLayout right={right}/>}>
                    <Route path="/admin/home" exact element={<HomePage/>}/>
                    <Route path="/admin/settings" exact element={<Settings/>}/>
                    <Route path="/admin/inscriptions" exact element={<Inscriptions/>}/>
                    <Route path="/admin/inscriptions/members" exact element={<MembersPage/>}/>
                    <Route path="/admin/inscriptions/indoor" exact element={<Indoors/>}/>
                    <Route path="/admin/inscriptions/outdoor" exact element={<Outdoors/>}/>

                    <Route path="/admin/team/course-list" exact element={<TeamCourseList/>}/>
                    <Route path="/admin/team/brevets" exact element={<TeamBrevets/>}/>
                    <Route path="/admin/team-panel" exact element={<TeamPanel/>}/>
                    <Route path="/admin/another/teams" exact element={<TeamsPage/>}/>

                    <Route path="/admin/member-list" exact element={<AdminMemberList/>}/>
                    <Route path="/admin/team-list" exact element={<AdminTeamList/>}/>
                    <Route path="/admin/inscriptions-list" exact element={<AdminInscriptionsList/>}/>
                    <Route path="/admin/panel" exact element={<AdminPanel/>}/>
                    <Route path="/admin/indoor" exact element={<AdminIndoor/>}/>
                    <Route path="/admin/indoorStage" exact element={<AdminInscriptionStage/>}/>
                    <Route path="/admin/subscription" exact element={<AdminSubscription/>}/>
                    <Route path="/admin/outdoor" exact element={<AdminOutdoor/>}/>
                    <Route path="/admin/brevets" exact element={<AdminBrevet/>}/>

                    <Route path="/Page-not-found" element={<h1>Page not found</h1>}/>
                    <Route path="/Payment-checkout-pass" element={<PaymentCheckoutPass/>}/>
                    <Route path="/Payment-checkout-fail" element={<PaymentCheckoutFail/>}/>
                </Route>


                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default Routers;
