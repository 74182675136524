import axios from "axios"
import {url} from "../utils/constants"

const client = axios.create({
    baseURL: url + "sections"
})

const getSectionList = async () => {
    try {
        const list = await client.get("/", {headers: {'Authorization': localStorage.getItem('token')}})
        return list.data;
    } catch (e) {
        console.error(e);
    }
}

const addSection = async (description, minAge, maxAge, level) => {
    try {
        const payload = {
            description: description,
            minAge: minAge,
            maxAge: maxAge,
            level: level
        }
        return await client.post("/", payload, {headers: {'Authorization': localStorage.getItem('token')}});

    } catch (e) {
        console.error(e);
    }
}
const deleteSection = async (id) => {
    try {
         await client.delete("/"+id, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        return e;
    }
}

const updateSection = async (id, description, minAge, maxAge, level) => {
    try {
        const payload = {
            description: description,
            minAge: minAge,
            maxAge: maxAge,
            level: level
        }
        return await client.put("/"+id, payload, {headers: {'Authorization': localStorage.getItem('token')}});
    } catch (e) {
        return e;
    }
}


export {
    getSectionList,
    addSection,
    deleteSection,
    updateSection
}
