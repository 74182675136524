import React, {useContext, useState} from 'react';
import AccountContext from "../../contexts/AuthenticationContext";
import * as  api from '../../services/AuthenticationService';
import {Link, useNavigate} from "react-router-dom";
import {RIGHT} from "../../utils/constants";
import {BeatLoader} from "react-spinners";
import {paswordForgot} from "../../services/AuthenticationService";

const Forgot = () => {
    const navigate = useNavigate();

    const {authenticate} = useContext(AccountContext);

    const [errorLogin, setErrorLogin] = useState();
    const [email, setEmail] = useState();
    const [loader,setLoader] = useState(false);
    const [outputMessage, setOutputMessage] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        setErrorLogin(null);
        try {
            setLoader(true);
            const resultForgot = paswordForgot(email);
            if(resultForgot === 'ERR_NETWORK'){
                navigate("/server_maintenance")
            }
            setOutputMessage('modifié✅ les modifications seront visible à la fermeture')
            setLoader(false);

        } catch (e) {
            setErrorLogin('⚠️e-mail ou mot de passe incorrect ⚠️');
            setLoader(false);
            console.error(e);
        }

    }

    return (
        <div className="min-h-screen bg-backgroundEV">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center ">
                {errorLogin &&
                    <>
                        <p className='text-red-700'>{errorLogin}</p>
                    </>
                }

                <form>
                    <label className="block mb-2">
                        <p>Email</p>
                        <input type="text" onChange={e => setEmail(e.target.value)}
                               autocomplete="username"
                               className="shadow bg-transparent border  rounded w-full py-2 px-3  leading-tight focus:outline-none focus:ring-2 focus:ring-pinkEv focus:border-transparent"
                               placeholder="you@example.com"/>
                    </label>
                    <div>
                        <button type="submit"
                                className="rounded font-bold bg-pinkEv border-gray-500 border-2 mt-4 p-3 focus:border-transparent hover:border-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1 "
                                onClick={handleSubmit}>
                            Demander un nouveau mots de passe
                            {loader &&
                                <BeatLoader  className="ml-2" color="#FFFFFF" size='10' />
                            }
                        </button>
                        {outputMessage &&
                            <p className="rounded bg-pinkEv-800 m-3 p-2 shadow-lg ">{outputMessage}</p>
                        }
                    </div>
                </form>


                <br/>

                <Link
                    to="/"
                    className="mt-5 rounded underline hover:text-pinkEv hover:border-pinkEv"
                >
                    Retour à la liste des cours
                </Link>

                <br/>

                <Link
                    to="/login"
                    className="mt-5 rounded underline hover:text-pinkEv hover:border-pinkEv"
                >
                    se connecter
                </Link>
            </div>
        </div>
    );
}

export default Forgot
