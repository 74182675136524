import React, {Fragment, useEffect, useState} from "react";

import Portal from "../Portal/Portal";
import {getAccount, getOrdersHistory} from "../../services/AccountService";
import {ifExpiredDisconnect} from "../../utils/toolbox";
import * as api from "../../services/AccountService";
import DataTable from "react-data-table-component";
import {DataTableTheme} from "../DataTableComponents/DataTableTheme";
import {getInscriptionsFromOrder} from "../../services/OrderService";
import {getCoursesList} from "../../services/CourseService";
import {manual_inscription_stage, stageList} from "../../services/StageService";
import {manual_inscription_subscription, subscription_list} from "../../services/SubscriptionService";
import Select from "react-select";
import {manual_inscription_course} from "../../services/InscriptionCoursesService";
import {getLocationList} from "../../services/LocationService";
import EditAccount from "./EditAccount";
import {useNavigate} from "react-router-dom";

const AccountDetailAdmin = ({onCancel, row, onUpdate}) => {
    const navigate = useNavigate();
    const [childrenList, setChildrenList] = useState()
    const [ordersHistoric, setOrdersHistoric] = useState([])
    const [inscriptionsContent, setInscriptionsContent] = useState()
    const [currentRow, setCurrentRow] = useState(null);
    const [lastName, setLastName] = useState(row.last_name)
    const [firstName, setFirstName] = useState(row.first_name)
    const [email, setEmail] = useState(row.email)
    const [phone, setPhone] = useState(row.phone)
    const [birthDate, setBirthDate] = useState(row.birth_date)
    const [locality, setLocality] = useState(row.locality)
    const [street, setStreet] = useState(row.street)
    const [gender, setGender] = useState(row.gender)
    const [manualInscription, setManualInscription] = useState(false)
    const [selectedOption, setSelectedOption] = useState()
    const [courseOptions, setCourseOptions] = useState()
    const [stageOptions, setStageOptions] = useState()
    const [subscriptionOptions, setSubscriptionOptions] = useState()
    const [selectedToInscr, setSelectedToInscr] = useState()
    const [update, setUpdate] = useState(false)
    const [error, setError] = useState()
    const [editAccountModal, setEditAccountModal] = useState(false)
    const [outputMessage, setOutputMessage] = useState();

    const defaultOptions = [
        {value: 'cours', label: 'cours'},
        {value: 'abonnement', label: 'abonnement'},
        {value: 'stage', label: 'stage'}
    ]

    const handleEditAccountModal = () => {
        setEditAccountModal(!editAccountModal)
    }
    const onCancelEditAccountModal = () => {
        setEditAccountModal(false)
    }

    useEffect(() => {
        ifExpiredDisconnect()

        Promise.all([api.getChildrens(row.id), getOrdersHistory(row.id), getCoursesList(), stageList(), subscription_list(), getLocationList()])
            .then(values => {
                setChildrenList(values[0]);
                const ordersFormater = () => {
                    //for each order format date from 2024-01-20T12:55:50.000Z to dd/mm/YY and add time param hh:mm:ss
                    let orders = values[1]
                    orders.forEach(order => {
                        if (order !== null) {
                            if (order.order_date) {
                                let pretime = order.order_date.split("T")[1]
                                order.order_time = pretime.split(".")[0]
                                order.order_date = order.order_date.split("T")[0].split("-").reverse().join("/")
                            }
                            if (order.status === 'STRIPE_CHECKOUT_SESSION_COMPLETED') {
                                order.status = <span
                                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Paid</span>
                            } else if (order.status === 'PAYMENT_CREATED') {
                                order.status = <span
                                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">Pending</span>
                            } else if (order.status === 'PAYMENT_FAILED') {
                                order.status = <span
                                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Failed</span>
                            } else if (order.status === 'CREATED') {
                                order.status = <span
                                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">panier</span>
                            } else if (order.status === 'STRIPE_CHECKOUT_SESSION_EXPIRED') {
                                order.status = <span
                                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Payment expirer</span>
                            }
                        }

                    })
                    return orders
                }
                setOrdersHistoric(ordersFormater());
                //put all list in the state allEVPossibleInscription which is an array
                let products = []
                products.push(values[2])
                //format course list for select component
                let courses = []
                let locations = values[5]
                values[2].forEach(course => {
                    // get the location of the course
                    let location = locations.find(loc => loc.id === course.location_id)
                    let name = course.week_day + " " + course.section.description + " " + course.begin_time.split(":")[0] + "h " + location.name
                    courses.push({value: course.id, label: name})
                })
                setCourseOptions(courses)
                products.push(values[3])
                let stages = []
                values[3].forEach(stage => {
                    stages.push({value: stage.id, label: stage.description})
                })
                setStageOptions(stages)
                products.push(values[4])
                let subscriptions = []
                values[4].forEach(subscription => {
                    subscriptions.push({value: subscription.id, label: subscription.description})
                })
                setSubscriptionOptions(subscriptions)
            }).catch(err => {
            console.log(err);
        })
    }, [update])

    const loadInscriptionsFromOrder = async (row) => {
        setCurrentRow(row)
        setInscriptionsContent([])
        const inscriptions = await getInscriptionsFromOrder(row.id)
        let inscriptionsContainer = []

        // collect all inscription from: inscriptions.inscriptionsCourses,inscriptions.inscriptionsTrainingCourses,inscriptions.inscriptionsSubscriptions,inscription.inscriptionsMembers,inscription.inscriptionsMembersCab
        if (inscriptions.inscriptionsCourses.length > 0) {
            inscriptions.inscriptionsCourses.forEach((inscr) => {
                inscr.inscription.description = inscr.course.week_day + " " + inscr.course.begin_time.substring(0, 5)
                inscr.inscription.section = inscr.section.description
                inscriptionsContainer.push(inscr.inscription)
            })
        }
        if (inscriptions.inscriptionsTrainingCourses.length > 0) {
            inscriptions.inscriptionsTrainingCourses.forEach((inscr) => {
                inscr.inscription.description = inscr.trainingCourse.description
                inscriptionsContainer.push(inscr.inscription)
            })
        }
        if (inscriptions.inscriptionsSubscriptions.length > 0) {
            inscriptions.inscriptionsSubscriptions.forEach((inscr) => {
                inscriptionsContainer.push(inscr.inscription)
            })
        }
        if (inscriptions.inscriptionsMembers.length > 0) {
            inscriptions.inscriptionsMembers.forEach((inscr) => {
                inscriptionsContainer.push(inscr.inscription)
            })
        }
        if (inscriptions.inscriptionsMembersCab.length > 0) {
            inscriptions.inscriptionsMembersCab.forEach((inscr) => {
                inscr.inscription.type = inscr.inscription.type + '_' + inscr.memberTypeCab.name
                inscriptionsContainer.push(inscr.inscription)
            })
        }

        //if no inscriptions set inscriptionsContainer to []
        if (inscriptions.inscriptionsMembersCab.length === 0 && inscriptions.inscriptionsMembers.length === 0 && inscriptions.inscriptionsSubscriptions.length === 0 && inscriptions.inscriptionsTrainingCourses.length === 0 && inscriptions.inscriptionsCourses.length === 0) {
            inscriptionsContainer = [{
                id: "created",
                type: "unfinished",
                begin_date: "aucun",
                end_date: "aucun",
                inscription_date: "aucun"

            }]
        }
        //for each inscription reformat end,begin and insciption date
        inscriptionsContainer.forEach(inscription => {
            if (inscription.begin_date) {
                inscription.begin_date = inscription.begin_date.split("T")[0].split("-").reverse().join("/")
            }
            if (inscription.end_date) {
                inscription.end_date = inscription.end_date.split("T")[0].split("-").reverse().join("/")
            }
            if (inscription.inscription_date) {
                inscription.inscription_date = inscription.inscription_date.split("T")[0].split("-").reverse().join("/")
            }
            //if type ==="trainingCourse" replace by stage, if type === "subscription" replace by abonnement
            if (inscription.type === "training_course") {
                inscription.type = "stage"
            }
            if (inscription.type === "subscription") {
                inscription.type = "abonnement"
                inscription.description = "abonnement"
            }
            if (inscription.type === "member_cab_indoor") {
                inscription.type = "cab indoor"
            }
            if (inscription.type === "membre_cab_outdoor") {
                inscription.type = "cab outdoor"
            }
            if (inscription.type === "member") {
                inscription.type = "membre"
                inscription.description = "cotisation"
            }
        })
        setInscriptionsContent(inscriptionsContainer)
    }


    let orderColumn = {
        columns: [
            {
                name: 'id',
                selector: row => row.id,
                sortable: true
            },
            {
                name: 'date',
                selector: row => row.order_date,
                sortable: true
            },
            {
                name: 'time',
                selector: row => row.order_time,
                sortable: true
            },
            {
                name: 'total',
                selector: row => row.total_price,
                sortable: true
            },
            {
                name: 'Status',
                selector: row => row.status,
                sortable: true,
            }


        ]
    }

    let inscriptionColumn = {
        columns: [
            {
                name: 'compte',
                selector: row => row.account_id,
            }, {
                name: 'start',
                selector: row => row.begin_date,
                grow: 2
            }, {
                name: 'end',
                selector: row => row.end_date,
                grow: 2
            }, {
                name: 'id inscirption',
                selector: row => row.id,
            }, {
                name: 'date',
                selector: row => row.inscription_date,
                grow: 2
            }, {
                name: 'type',
                selector: row => row.type,
                grow: 2
            },
            {

                name: 'section',
                selector: row => row.section,
                grow: 2
            },
            {
                name: 'description',
                selector: row => row.description,
                grow: 3
            },
            {
                name: 'prix',
                selector: row => row.price,
            }, {
                name: 'reduction',
                selector: row => row.reduction,
            }]
    }


    const handleManuallyRegister = async () => {
        try {

            let result
            if (selectedOption.value === "cours") {
                result = await manual_inscription_course(row.id, selectedToInscr.value);
            } else if (selectedOption.value === "stage") {
                result = await manual_inscription_stage(row.id, selectedToInscr.value);
            } else if (selectedOption.value === "abonnement") {
                result = await manual_inscription_subscription(row.id, selectedToInscr.value);
            }

            if (result === 'ERR_NETWORK') {
                navigate("/server_maintenance")
            }
            setOutputMessage('inscrit✅')

        } catch (e) {
            setError(e.response.data.message)
            console.error(e);
            setOutputMessage('erreur❌')
        }
        setUpdate(!update)
    }


    DataTableTheme()

    const ExpandedComponent = () => {

        return (<pre className='w-max'><DataTable columns={inscriptionColumn.columns} data={inscriptionsContent}
                                                  showGridlines
                                                  highlightOnHover/></pre>);
    }


    //align on left a column with the row info and on left the list of inscriptions
    return (
        <Fragment>
            <div className="fixed inset-0 bg-black opacity-25 "/>
            <Portal className='overflow-auto scroll-auto'>
                <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                <div
                    className=" flex flex-auto flex-col  bg-white rounded-lg  p-4 fixed inset-x-0 bottom-24 z-50 m-auto md:relative">
                    <div className='flex '>
                        <div className='flex flex-col my-auto'>
                            {row.active === true ?
                                <div><span className="font-bold"> compte:</span> <span
                                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">actif</span>
                                </div>
                                :
                                <div> compte: <span
                                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">inactif</span>
                                </div>
                            }

                            <div><span className="font-bold">id:</span> {row.id}</div>
                            <div><span className="font-bold">nom prénom:</span>
                                <span> {lastName},{firstName}</span>
                            </div>
                            <div><span className="font-bold">date de naissance: </span><span> {birthDate}</span>
                            </div>
                            {email &&
                                <div><span className="font-bold">email:</span><span> {row.email} </span></div>
                            }
                            {phone &&
                                <div><span className="font-bold">téléphone:</span><span>{row.phone}</span></div>
                            }
                            <div><span
                                className="font-bold">addresse:</span> {row.house_number + ' ' + row.street + ' ' + row.locality + ' ' + row.postal_code + ' boite' + row.box}
                            </div>
                            <div><span className="font-bold">ville:</span> {row.locality}</div>
                            <div><span className="font-bold">sexe:</span> {row.gender}</div>
                            <div><span className="font-bold">club:</span> {row.club}</div>
                            <div><span className="font-bold"> rentrer seul:</span> {row.allow_going_home_alone}</div>
                            {!row.allow_going_home_alone &&
                                <div><span className="font-bold"> responsible:</span> {row.responsible_of_retrieving}
                                </div>
                            }
                            {row.parent_id.props.children[1] !== null &&
                                <div className='flex flex-wrap'><span className="font-bold"> parentId:</span>
                                    <div className="underline hover:text-pinkEv">
                                        <a>{row.parent_id.props.children[1]}</a>
                                    </div>
                                </div>
                            }
                            <div><span className="font-bold">niveau:</span> {row.level}</div>
                            <div><span className="font-bold">t-shirt:</span>{row.t_shirt}</div>

                            <div>
                                <button onClick={onCancel}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-500 ease-in-out transform hover:-translate-y-1">
                                    Fermer
                                </button>
                                <button onClick={handleEditAccountModal}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded m-2 transition duration-500 ease-in-out transform hover:-translate-y-1">
                                    modifier
                                </button>
                                <button
                                    onClick={() => {
                                        setManualInscription(!manualInscription)
                                        setSelectedOption(null)
                                    }}
                                    className=" bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded m-2 transition duration-500 ease-in-out transform hover:-translate-y-1 ">
                                    inscrire manuellement
                                </button>
                                {manualInscription &&
                                    <div className='flex flex-wrap space-x-4 '>
                                        <Select options={defaultOptions} onChange={setSelectedOption}/>
                                        {selectedOption &&
                                            <>
                                                <>
                                                    {selectedOption.value === "cours" &&
                                                        <Select options={courseOptions} onChange={setSelectedToInscr}/>
                                                    }
                                                </>
                                                <>
                                                    {selectedOption.value === "abonnement" &&
                                                        <Select options={subscriptionOptions}
                                                                onChange={setSelectedToInscr}/>
                                                    }
                                                </>
                                                <>
                                                    {selectedOption.value === "stage" &&
                                                        <Select options={stageOptions} onChange={setSelectedToInscr}/>
                                                    }
                                                </>

                                                <button
                                                    className=" bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded  transition duration-500 ease-in-out transform hover:-translate-y-1 "
                                                    onClick={handleManuallyRegister}> inscrire
                                                </button>
                                                {outputMessage &&
                                                    <p className="rounded bg-pinkEv-800 m-3 p-2 shadow-lg ">{outputMessage}</p>
                                                }
                                                {error &&
                                                    <p className="rounded bg-pinkEv-800 m-3 p-2 shadow-lg ">{error}</p>
                                                }

                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            //table with all the children next to the last list
                        }
                        <div className="m-auto flex flex-column">
                            <h2 className="text-xl font-bold">membres de la famille</h2>
                            <div className="mt-2">
                                {childrenList && childrenList.map(child => (
                                    <div key={child.id}>
                                        <div
                                            className="text-lg font-bold">{child.last_name + ' ' + child.first_name}</div>
                                        <div className="text-sm">id: {child.id}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {
                        //lister les inscriptions
                    }
                    <div>
                        {ordersHistoric.length !== 0 &&
                            <div className=' m-auto flex flex-column'>
                                <h2 className="text-xl font-bold">Historique des inscriptions</h2>
                                <div className="mt-2">
                                    <DataTable
                                        columns={orderColumn.columns}
                                        data={ordersHistoric}
                                        expandableRows
                                        expandableRowExpanded={(row) => (row === currentRow)}
                                        onRowClicked={(row) => setCurrentRow(row)}
                                        expandableRowsComponent={ExpandedComponent}
                                        onRowExpandToggled={(bool, row) => loadInscriptionsFromOrder(row)}
                                        showGridlines
                                        pagination
                                        highlightOnHover
                                        paginationRowsPerPageOptions={[1, 5, 10, 20, 25, 50, 100]}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {editAccountModal &&
                    <EditAccount onCancel={onCancel}
                                 onUpdate={onUpdate}
                                 account={row}/>
                }
            </Portal>
        </Fragment>
    );
};

export default AccountDetailAdmin;
