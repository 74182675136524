import React, {useContext, useEffect, useState} from "react";
import * as apiCourses from "../../../services/CourseService";
import * as apiLocation from "../../../services/LocationService";
import * as apiSection from "../../../services/SectionService";
import * as api from "../../../services/AccountService";
import * as apiInscription from "../../../services/InscriptionCoursesService";
import SelectorUser from "../../../components/InscriptionsComponents/CollectivesForms/SelectorUser";
import CartContext from "../../../contexts/CartContext";
import {useNavigate} from "react-router-dom";
import {calculateAgeOnNextJan1, ifExpiredDisconnect} from "../../../utils/toolbox";
import {LEVEL_RANKS} from "../../../utils/constants";


const Collectives = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [courses, setCourses] = useState([]);
    const [locations, setLocations] = useState([]);
    const [sections, setSections] = useState([]);
    const [courseFiltered, setCourseFiltered] = useState([]);
    const [sectionsFilteredByAgeOfSelect, setSectionsFilteredByAgeOfSelect] = useState([]);
    const [account, setAccount] = useState([]);
    const [childList, setChildList] = useState([]);
    // const [annual, setAnnual] = useState(0);
    const {updateCart} = useContext(CartContext);
    // const semester = [{
    //     value: "full_year_price", label: "Septembre-Juin"
    // }, {value: "first_half_year_price", label: "Septembre-Décembre"}, {
    //     value: "second_half_year_price", label: "Janvier-Juin"
    // }];

    const [selectedAccount, setSelectedAccount] = useState(); // can be the selected child or the current user
    const [selectedSection, setSelectedSection] = useState();
    const [selectedLocation, setSelectedLocation] = useState();
    const [selectedCourse, setSelectedCourse] = useState();
    const [selectedSemester, setSelectedSemester] = useState();
    const [whichSemester, setWhichSemester] = useState();
    const [childAndAccountSelect, setChildAndAccountSelect] = useState([]);
    const [update, setUpdate] = useState(false);


    useEffect(() => {
        let array = []
        let tab = []
        const currentDate = new Date();
        //if the current date is between september and december, the semester is the first half
        ifExpiredDisconnect()

        if (currentDate.getMonth() >= 8 && currentDate.getMonth() <= 10) {
            setWhichSemester("firstHalfYear");

        } else {
            setWhichSemester("secondHalfYear");
        }

        const getdatas = async () => {
            const output = await Promise.all([apiCourses.getCoursesList(), apiLocation.getLocationList(), apiSection.getSectionList()]);
            // set only courses that are not reserved
            let coursesAvailable = output[0].filter(course => {
                if (course.reserved === false) return course;
            })
            setCourses(coursesAvailable);
            // location
            setLocations(output[1]);
            array = []
            output[1].forEach(location => {
                array.push({value: location.id, label: location.name})
            })
            setLocations(array);
            // section
            setSections(await output[2]);
            array = []
            output[2].forEach(section => {
                array.push(section)
            })
            setSections(array);
        }
        const children = async () => {
            let children = await api.getCurrentChildrens()
            let account = await api.getCurrentAccount()
            setChildList(children);
            setAccount(account);
            children.forEach(child => {
                tab.push({value: child.id, label: child.first_name + " " + child.last_name})
            })
            tab.push({value: account.id, label: account.first_name + " " + account.last_name})
            setChildAndAccountSelect(tab);
        }
        //filiter the select by the age of the account
        const filterSectionsByAgeOfSelect = async (selectedOption) => {
            let today = new Date();
            if (selectedOption !== undefined) {
                try {
                    let accountChoosen
                    if (selectedOption === account.id) {
                        accountChoosen = await api.getCurrentAccount()
                    } else {
                        accountChoosen = await api.getChildAccount(selectedOption)
                    }
                    //change the birthdate to a date object
                    const age = calculateAgeOnNextJan1(accountChoosen.birth_date)
                    //add 1 if he will older at 1 of january next year


                    let sectionsFilteredByAgeOfSelect = sections.filter(section => {
                        return section.min_age <= age && section.max_age >= age;
                    });
                    //format the sectionsFilteredByAgeOfSelect for the select component
                    let sectionsFilteredByAgeOfSelectTab = []
                    sectionsFilteredByAgeOfSelect.forEach(section => {
                        sectionsFilteredByAgeOfSelectTab.push({value: section.id, label: section.description})
                    })
                    setSectionsFilteredByAgeOfSelect(sectionsFilteredByAgeOfSelectTab);
                } catch (e) {
                    if (e.status === 401) {
                        localStorage.clear();
                        navigate("/");
                        console.error(e.message);
                    }
                }
            }
        }
        const filterCourseByFilteredSection = async () => {
            //get all the course that have a section_id in the sectionsFilteredByAgeOfSelect and the course location_id in the selectedLocation
            let coursesFiltered = courses.filter(course => course.section_id === selectedSection && course.location_id === selectedLocation);
            let coursesFilteredTab = []
            coursesFiltered.forEach(course => {
                //format the coursesFiltered for the select component
                coursesFilteredTab.push({
                    value: course.id,
                    label: course.week_day + " " + course.begin_time + " - " + course.end_time
                })
            })
            setCourseFiltered(coursesFilteredTab);
        }

        children();
        getdatas();
        filterSectionsByAgeOfSelect(selectedAccount);
        filterCourseByFilteredSection();

    }, [update]);


    const handleChildSelect = (selectedOption) => {
        setSelectedAccount(selectedOption);
        setSelectedSection(null);
        setSelectedLocation(null);
        setSelectedCourse(null);

        onUpdate();
    }
    const handleLocationSelect = (selectedOption) => {
        setSelectedLocation(selectedOption);
        setSelectedSection(null);
        setSelectedCourse(null);
        onUpdate();
    }
    const handleSectionSelect = (selectedOption) => {
        setSelectedSection(selectedOption);
        setSelectedCourse(null);
        setSelectedSemester(null);
        onUpdate();
    }

    const handleCourseSelect = (selectedOption) => {
        setSelectedCourse(selectedOption);
        onUpdate();
    }

    // const handleSemesterSelect = (selectedOption) => {
    //     setSelectedSemester(selectedOption);
    //     onUpdate();
    // }

    const handleAddToCart = async (courseID, semester) => {
        try {
            //add the course to the cart
            await apiInscription.inscriptions_course(selectedAccount, courseID);
            onUpdate();
            updateCart();
        } catch (e) {
            let errorMessage = e.response.data.message;
            switch (errorMessage) {
                case "Cannot register account to the course, order is in payment process":
                    setError("Vous avez un payment en cours,\n merci de le finaliser ou annuler avant de commencer un autre panier");
                    break;
                case "The course has reached its capacity":
                    setError("le cours est complet");
                    break;
                case "Validation error":
                    setError("Vous êtes déjà inscrit OU votre cours est déjà dans votre panier");
                    break;
                default:
                    setError(errorMessage);
            }
        }

    }


    const onUpdate = () => {
        setUpdate(!update)
    }

    //if child is selected then show a select box with all the children and the account and a button to add a new child
    return (<div className="">
        {courses.length > 0 ?
            <>
                <SelectorUser userList={childAndAccountSelect} selectedAccount={selectedAccount}
                              handleChildSelect={handleChildSelect}
                              locationList={locations} handleLocationSelect={handleLocationSelect}
                              sectionList={sectionsFilteredByAgeOfSelect} handleSectionSelect={handleSectionSelect}
                              courseFiltered={courseFiltered}
                              courseList={courses} handleCourseSelect={handleCourseSelect}
                              handleAddToCart={handleAddToCart}
                              selectedLocation={selectedLocation} selectedSection={selectedSection}
                              selectedCourse={selectedCourse} selectedSemester={selectedSemester}
                              onUpdate={onUpdate}
                              error={error}
                              setError={setError}

                />
            </>
            :
            <div className="flex flex-col items-center justify-center ">

                <div className=" h-max ">
                    <h1 className="text-2xl font-bold text-red-600 m-5">
                        Les inscriptions sont actuellement fermées
                    </h1>
                </div>
            </div>
        }
    </div>);


}
export default Collectives;