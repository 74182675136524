import React, {Fragment, useEffect, useMemo, useState} from "react";
import Portal from "../Portal/Portal";
import {getStudents} from "../../services/CourseService";
import AssignBrevetStudentModal from "./AssignBrevetStudentModal";
import {DataTableTheme} from "../DataTableComponents/DataTableTheme";
import FilterComponent from "../DataTableComponents/FilterComponent";
import CsvDownloadButton from "react-json-to-csv";
import DataTable from "react-data-table-component";
import {BarLoader} from "react-spinners";

const ShowStudentsModal = ({selectedCourse, onCancel}) => {
    const [courseStudentList, setCourseStudentList] = useState([])
    const [assignBrevetModal, setAssignBrevetModal] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState({});
    const [formatedList, setFomatedList] = useState([]);
    const [pending, setPending] = React.useState(true);


    useEffect(() => {
        const handleShowStudent = async () => {
            const courseStudent = await getStudents(selectedCourse)
            await setPending(false);
            setCourseStudentList(courseStudent)
            courseStudent.forEach(member => {
                if (member.image_right === 0) {
                    member.image_right = <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">non</span>
                }else{
                    member.image_right = <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">oui</span>
                }
            })
            setFomatedList(formatData(courseStudent))
        }
        const formatData = (data) => {
            let table = {
                columns: [
                    {
                        name: 'prénom',
                        selector: row => row.first_name,
                        sortable: true,
                    },
                    {
                        name: 'nom',
                        selector: row => row.last_name,
                        sortable: true,
                    },
                    {
                        name: 'email',
                        selector: row => row.email,
                        sortable: true,
                        grow: 3,
                    },
                    {
                        name: 'phone',
                        selector: row => row.phone,
                        sortable: true,
                        grow: 2,
                    },
                    {
                        name: 'sex',
                        selector: row => row.gender,
                        sortable: true,
                    },
                    {
                        name: 'birthdate',
                        selector: row => row.birth_date,
                        sortable: true,
                    },
                    {
                        name: 't-shirt',
                        selector: row => row.t_shirt,
                        sortable: true,
                    },
                    {
                        name:'droit image',
                        selector:row=>row.image_right,
                        sortable:true
                    },
                    {
                        name: 'action',
                        selector: row => <button onClick={(e) => {
                            e.preventDefault();
                            handleShowAssignBrevet();
                            handleSelectStudent(row.id, row.last_name, row.first_name)
                        }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded">Assign
                            Brevet/Retirer un brevet</button>,
                        sortable: false,
                        width: '270px'
                    }

                ],
                rows: data
            }
            return table
        }

        handleShowStudent()
    }, [])

    const handleShowAssignBrevet = () => {
        setAssignBrevetModal(true);
    }
    const onCancelAssignBrevetModal = () => {
        setAssignBrevetModal(false);
    }

    const handleSelectStudent = (id, last_name, first_name) => {
        const student = {
            value: id,
            label: last_name + " " + first_name,
        }
        setSelectedStudent(student);
    }


    DataTableTheme()

    return (
        <Fragment>
            <div onClick={onCancel} className="fixed inset-0 bg-black opacity-25" />
            <Portal>
                <div onClick={onCancel} className="bg-black opacity-50 fixed inset-0 z-40" />
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="bg-backgroundEV rounded-lg w-full overflow-hidden">
                        <div className="p-4">
                            <div className="flex justify-end">
                                <button
                                    onClick={() => {
                                        onCancel();
                                    }}
                                    className="border rounded p-1 m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-gray-300 text-black"
                                >
                                    fermer
                                </button>
                            </div>
                            <form className="mt-4">
                                {courseStudentList.length > 0 ? (
                                    <div className="overflow-auto shadow-md sm:rounded-lg max-h-[90vh]">
                                        <div className="mb-4">
                                            <CsvDownloadButton
                                                className="border bg-1c1d26 font-bold rounded p-1 border-pinkEv text-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv hover:text-white"
                                                data={courseStudentList}
                                                filename={selectedCourse}
                                            >
                                                télécharger le CSV
                                            </CsvDownloadButton>
                                        </div>

                                        <DataTable
                                            columns={formatedList.columns}
                                            data={courseStudentList}
                                            showGridlines
                                            theme="TableEV"
                                            pagination
                                            highlightOnHover
                                            progressPending={pending}
                                            progressComponent={<BarLoader color="#e44c65" size="100" />}
                                            paginationRowsPerPageOptions={[20, 25, 50, 100]}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <h1>aucun élève inscrit</h1>
                                    </div>
                                )}
                            </form>
                            {assignBrevetModal && (
                                <AssignBrevetStudentModal onCancel={onCancelAssignBrevetModal} student={selectedStudent} />
                            )}
                        </div>
                    </div>
                </div>
            </Portal>
        </Fragment>
    );
};

export default ShowStudentsModal;
