import {jwtDecode} from "jwt-decode";
import {TOKEN} from "./constants";

export const checkExpiration = ()=> {
    const token = localStorage.getItem(TOKEN);
    if (!token) {
        return false;
    } else {
        const decoded = jwtDecode(token);
        return decoded.exp > Date.now() / 1000; // check if the token is not expired
    }
}

export const ifExpiredDisconnect = () => {
    if (!checkExpiration()) {
        localStorage.clear();
        window.location.reload()
    }else {
        return false;
    }
}


export const calculateAge = (birthdate) => {
    const today = new Date();
    const birthdateDate = new Date(birthdate);

    let age = today.getFullYear() - birthdateDate.getFullYear();
    const monthDiff = today.getMonth() - birthdateDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdateDate.getDate())) {
        age--;
    }
  return age;
};

export const calculateAgeOnNextJan1 = (birthDate) => {
    const today = new Date();
    const nextJan1 = new Date(today.getFullYear() + 1, 0, 1); // January 1st of next year
    let age = nextJan1.getFullYear() - new Date(birthDate).getFullYear();
    // If their birthday hasn't occurred yet this year, subtract 1 from the age
    const thisYearsBirthday = new Date(nextJan1.getFullYear(), new Date(birthDate).getMonth(), new Date(birthDate).getDate());
    if (nextJan1 < thisYearsBirthday) {
        age--;
    }
    return age;
}




export function getCircularReplacer() {
    const ancestors = [];
    return function (key, value) {
        if (typeof value !== "object" || value === null) {
            return value;
        }
        // `this` is the object that value is contained in,
        // i.e., its direct parent.
        while (ancestors.length > 0 && ancestors.at(-1) !== this) {
            ancestors.pop();
        }
        if (ancestors.includes(value)) {
            return "[Circular]";
        }
        ancestors.push(value);
        return value;
    };
}
