import { LuInfo } from 'react-icons/lu';
import {useState} from "react"; // Assurez-vous d'installer react-icons si ce n'est pas déjà fait

const InfoIconWithTooltipRight = ({info}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="relative inline-block ">
            <LuInfo
                className="text-lg cursor-pointer absolute -top-2 "
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            />
            {showTooltip && (
                <div className="absolute bottom-full -right-52 mb-2 w-48 p-2 bg-white border border-gray-200 shadow-lg rounded shadow-lg">
                    <p className="text-sm text-gray-700">{info}</p>
                </div>
            )}
        </div>
    );
};

export default InfoIconWithTooltipRight;